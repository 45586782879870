import React from 'react';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {CompanySection, CustomerViewSection, DocumentDetailsSection, PresentationTitleBlockSection} from './ToolboxSections';

export function PresentationHeaderToolbox() {

  return (
    <SalesDocToolbox heading={'SalesPresentation'} noBack>
      <CustomerViewSection />
      <CompanySection />
      <DocumentDetailsSection />
      <PresentationTitleBlockSection />
    </SalesDocToolbox>
  );
}
