import * as React from 'react';
import {Column, Fields, Row} from '../../../componentsLib/Layout';
import {useListSalesDocRevisions} from '../../../hooks/api';
import {BusySpinner, Button, Link, UserAvatar} from '../../../componentsLib/Basic';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {formatDateTimeRelative} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';
import {SalesDoc} from '../Models/SalesDoc';

registerGlobalStyle('.revision-history-item', (theme) => ({
  svg: {color: theme.colors.text.mediumIcon},
  '&:hover svg': {color: theme.colors.text.highlight},
  a: {color: 'inherit'},
}));

export function SalesDocRevisionsTab({salesDoc}) {
  const {data: {salesDocs: _salesDocs}, isLoading} = useListSalesDocRevisions(salesDoc._id);
  const salesDocs = _salesDocs?.filter((doc) => doc.updatedBy?.fullName != null);

  return (
    <Column paper fillWidth pad gap>
      {isLoading &&
        <BusySpinner message={'Loading history'} left/>
      }
      {!isLoading && salesDoc?.documentType === SalesDoc.Type.CLASSIC_QUOTE &&
        'BackTrack is not available on classic quotes. Edit the quote and convert it to a SalesDoc to use BackTrack.'
      }
      {!isLoading && salesDoc?.documentType !== SalesDoc.Type.CLASSIC_QUOTE && !salesDocs?.length &&
        'No revisions recorded.'
      }
      {!isLoading && salesDoc?.documentType !== SalesDoc.Type.CLASSIC_QUOTE && salesDocs?.length > 0 && salesDocs.map((doc, index) => (
        <Row key={doc._id} className={'revision-history-item'} alignCenter gap>
          <UserAvatar name={doc.updatedBy.fullName}/>
          <Fields leftLabel noRowGap noColumnGap>
            <CaptionText text={'Updated at'}/>
            <BodyText text={formatDateTimeRelative(doc.document.updatedAt)}/>
            <CaptionText text={'Updated by'}/>
            <BodyText text={doc.updatedBy.fullName}/>
          </Fields>
          {index === 0 &&
            <Button navStandard text={'Current Version'} disabled/>
          }
          {index !== 0 &&
            <Button navStandard>
              <Link to={`/sales/edit/${salesDoc.number}/revision/${doc._id}`}>
                View this version
              </Link>
            </Button>
          }
        </Row>
      ))}
    </Column>
  );
}
