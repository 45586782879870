import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Document, Page, pdfjs} from 'react-pdf/dist/cjs/entry.webpack';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {HoopsIconButton} from '../../componentsOld/HoopsIconButton';
import {RectangularSkeleton} from '../Skeletons';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ZOOMSCALE = 2;
const HOVERPREVIEWSIZE = 500;

registerGlobalStyle('.image-preview', (theme) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
  flexDirection: 'column',

  '.image': {
    width: '100%',
    height: '100%',
  },

  '&.hover-outline': {
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.in.border,
    '&:hover': {
      borderColor: theme.colors.border.highlight,
      transition: theme.transitions.out.border,
    },
  },

  '&:hover': {
    '.hover-zoom-target': {
      opacity: .3,
      transition: theme.transitions.in.opacity,
    },
    '.hover-zoom': {
      opacity: 1,
      transition: theme.transitions.in.opacity,
    }
  },

  '.hover-zoom-target': {
    position: 'absolute',
    width: `${HOVERPREVIEWSIZE / ZOOMSCALE}px`,
    height: `${HOVERPREVIEWSIZE / ZOOMSCALE}px`,
    background: theme.colors.background.white,
    border: `1px solid ${theme.colors.border.main}`,
    opacity: 0,
    transition: theme.transitions.out.opacity,
    zIndex: theme.zIndex.hovers,
    '&.disabled': {display: 'none'}
  },

  '.hover-zoom': {
    position: 'absolute',
    width: HOVERPREVIEWSIZE,
    height: HOVERPREVIEWSIZE,
    top: 0,
    background: theme.colors.palette.white,
    backgroundSize: `${ZOOMSCALE * 100}%`,
    backgroundRepeat: 'no-repeat',
    boxShadow: theme.shadows.hoverElevation,
    opacity: 0,
    transition: theme.transitions.out.opacity,
    zIndex: theme.zIndex.hovers,
    pointerEvents: 'none',
  },

  '.pagination-controller': {
    position: 'relative',
    background: 'white',
    opacity: 100,
    boxShadow: theme.shadows.paginationElevation,
    borderRadius: 8,
    marginTop: theme.spacing(3),
    padding: theme.spacing(1)
  }
}));

export function ImagePreview({className, imageUrl, caption, contentType, hoverOutline, hoverZoom, selected, uploaded, setByContainerWidth, setByContainerHeight, onClick, pagination}) {
  className = classNames([
    className,
    'image-preview',
    hoverOutline && 'hover-outline',
    (selected != null || onClick) && 'selectable',
    selected && 'selected',
    uploaded && 'uploaded',
    caption && 'captioned',
  ]);

  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hoverZoomPos, setHoverZoomPos] = useState({left: 0, top: 0});
  const [hoverZoomTargetPos, setHoverZoomTargetPos] = useState({left: 0, top: 0});
  const [loadTypeFailed, setLoadTypeFailed] = useState({image: false, pdf: false});
  const imagePreviewRef = useRef();
  const zoomTargetRef = useRef();

  useEffect(() => {
    setNumberOfPages(0);
    setLoadTypeFailed({image: false, pdf: false});
  }, [imageUrl]);

  function onDocumentLoadSuccess({numPages}) {
    setNumberOfPages(numPages);
  }

  function onImageLoadError() {
    setLoadTypeFailed((prev) => ({...prev, image: true}));
  }

  function onPdfLoadError() {
    setLoadTypeFailed((prev) => ({...prev, pdf: true}));
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage(e) {
    e.stopPropagation();
    changePage(-1);
  }

  function nextPage(e) {
    e.stopPropagation();
    changePage(1);
  }

  // Below is the zoom hover functionality
  // When the user hovers over the preview image 2 things happen
  // - an enlarged preview showing a section of the image will appear to the right of the image
  // - a square transparent box (zoom-target) appears under the mouse cursor that follows the cursor and represents what is being shown on the right

  // these constants are set outside of the function to prevent the onMouseMove from running slow
  const imagePreviewProperties = imagePreviewRef.current?.getBoundingClientRect();
  const zoomTargetProperties = zoomTargetRef.current?.getBoundingClientRect();
  let left = 0;
  let top = 0;

  function onMouseMove(e) {
    // These 3 conditions prevent the zoom-target from leaving the bounds of the preview image on the x axis
    if (e.clientX - imagePreviewProperties.left - (zoomTargetProperties.width / 2) <= 0) {
      left = 0;
    } else if (e.clientX + (zoomTargetProperties.width / 2) - imagePreviewProperties.left >= imagePreviewProperties.width) {
      left = imagePreviewProperties.width - zoomTargetProperties.width;
    } else {
      left = e.clientX - imagePreviewProperties.left - (zoomTargetProperties.width / 2);
    }

    // These 3 conditions prevent the zoom-target from leaving the bounds of the preview image on the y axis
    if (e.clientY - imagePreviewProperties.top - (zoomTargetProperties.height / 2) <= 0) {
      top = 0;
    } else if (e.clientY + (zoomTargetProperties.height / 2) - imagePreviewProperties.top >= imagePreviewProperties.height) {
      top = imagePreviewProperties.height - zoomTargetProperties.height;
    } else {
      top = e.clientY - imagePreviewProperties.top - (zoomTargetProperties.width / 2);
    }

    // set the zoom-target position
    setHoverZoomTargetPos({left, top});
    // set the zoomed image position relative to the zoom-target
    setHoverZoomPos({left: -zoomTargetRef.current.offsetLeft * ZOOMSCALE, top: -zoomTargetRef.current.offsetTop * ZOOMSCALE});
  }

  return (
    <div ref={imagePreviewRef} className={className} onClick={onClick} onMouseMove={onMouseMove}>

      {(contentType !== 'application/pdf' && !loadTypeFailed.image) &&
        <>
          <img src={imageUrl} alt='' onError={onImageLoadError} className={'image'} />

          <div
            ref={zoomTargetRef}
            className={classNames(['hover-zoom-target', 'pdf-view-hidden', !hoverZoom && 'disabled'])}
            style={{
              left: hoverZoomTargetPos.left,
              top: hoverZoomTargetPos.top
            }}
          />
          {(hoverZoom && imagePreviewRef.current) &&
            <div
              className={'hover-zoom pdf-view-hidden'}
              style={{
                width: imagePreviewRef.current.offsetWidth,
                // make the hover zoom appear to the left of preview by using it's width to set left
                left: imagePreviewRef.current.offsetWidth,
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: `left ${hoverZoomPos.left}px top ${hoverZoomPos.top}px`
              }}
            />
          }
        </>
      }

      {contentType === 'application/pdf' || (loadTypeFailed.image && !loadTypeFailed.pdf) &&
        <Document
          file={imageUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onPdfLoadError}
          className={'pdf-doc'}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            className={'pdf-page'}
            width={setByContainerWidth && imagePreviewRef.current?.offsetWidth}
            height={setByContainerHeight && imagePreviewRef.current?.offsetHeight}
          />
        </Document>
      }

      {loadTypeFailed.image && loadTypeFailed.pdf &&
        <RectangularSkeleton rounded animation={false} />
      }

      {(numberOfPages > 1 && pagination) &&
        <div className={'pagination-controller'}>
          <HoopsIconButton purpose='previous' size='small' onClick={previousPage} disabled={pageNumber <= 1} />
          {pageNumber || (numberOfPages ? 1 : '--')} of {numberOfPages || '--'}
          <HoopsIconButton purpose='next' size='small' onClick={nextPage} disabled={pageNumber >= numberOfPages} />
        </div>
      }

    </div>
  );
}

ImagePreview.propTypes = {
  className: HoopsPropTypes.className,
  imageUrl: HoopsPropTypes.string,
  caption: HoopsPropTypes.string,
  contentType: HoopsPropTypes.string,
  hoverOutline: HoopsPropTypes.bool,
  hoverZoom: HoopsPropTypes.bool,
  onClick: HoopsPropTypes.func,
  pagination: HoopsPropTypes.bool,
  setByContainerWidth: HoopsPropTypes.bool,
  setByContainerHeight: HoopsPropTypes.bool,
};
