import {useActionApi, useApiCache, useCreateApi, useDeleteApi, useFetchApi, useGetApi, useListApi, useSaveApi, useUpdateApi} from './apiHelper';
import {useGetApiOld, usePatchApiOld, usePostApiOld, useQueryApiOld} from './apiHelperOld';

// =====================================================================================================================
//
// There are three basic flavours of the hook APIs
//
// useGetEntity     A hook used in the same way as useState, give it the entity ID required, and it will trigger a
//                  rerender when the entity is ready. If the ID changes, the new entity will be requested and a
//                  rerender will happen when it is ready.
//
// useFetchEntity   A hook which returns a function that can be used to fetch the entity. This does not maintain the
//                  entity in state (it is still cached under the hood), instead, calling fetch will return a promise
//                  that resolves with the response from the API.
//
// useListEntities  A hook also used like useState, give it the query and/or search string, as well as other parameters
//                  for paging, sorting etc., and it will fetch a list of the entities. If the parameters change, the
//                  list will be updated for the new parameters and a rerender will be triggered when the new data is
//                  ready.
//
// The get and fetch APIs always return the full entity, including peripheral references. The list API may return a
// subset of the full entity.
//
// =====================================================================================================================
//
// The standard parameters to the get and fetch API are:
//
// id:              The id of the entity to retrieve
//
// The standard response from the list API contains
//
// item:            The item requested, the actual name of this field will vary by API. For example, the getProduct API
//                  returns the data in the 'product' field.
// requested:       The original parameters passed to the API
//
// =====================================================================================================================
//
// The standard parameters to the list API are:
//
// page:            Page number to return, if paging is desired
// pageSize:        Size of the page to return, if paging is desired
// skip:            Number of entities to skip before returning results
// limit:           Maximum number of entities to return
// query:           Query to run to filter the results
// search:          String to search for / filter by
// sort:            How to sort the results
//
// All parameters are optional, the back end will use sensible defaults if they are not provided.
//
// Using the page or pageSize parameters will enable paging, which will return a total count of matching entities.
//
// The standard response from the list API contains
//
// items:           Array of items that match the query, the actual name of this field will vary by API. For example,
//                  the listProducts API returns the data in the 'products' field.
// total:           The total number of items that match the query. This is used for paging and requires additional
//                  backend processing, so is only returned if page is set.
// requested:       The original parameters passed to the API
//
// =====================================================================================================================
//
// In addition, the API operation can be changed with options:
//
// cacheMode:       Determines how the cache is used, more info below
// - Normal:        For the get and list APIs, this will immediately return the cached value, then trigger a rerender
//                  once the network version is returned. For the fetch API, this will either immediately return the
//                  cached version, or fulfill the promise once the API returns from the network.
// - Always:        Use the cached entity, if the entity is not yet cached use the network
// - Never:         Skip the cache and go straight to the network
// debounce:        A period in milliseconds, to ensure that frequent changes to the parameters do not trigger a flood
//                  of requests to the backend. Defaults to 300. When debouncing, the API will be called with the
//                  initial parameter values, then no more frequently than the debounce period.
// enabled:         By default, the API will not be executed unless and until either search or query are set. This flag
//                  can be used to override the default behavior. A true value will force execution of the API, and a
//                  false value will prevent execution.
// throwOnError:    By default, errors are handled by showing a popup toast. If throwOnError is true the error will be
//                  returned and no toast will be shown.
// successMessage:  Toast message displayed when the API succeeds. By default, no toast is displayed. This may be a
//                  string or a function returning a string. The function has the following prototype:
//                      ({method, path, id, params, data, body}) => message
// errorMessage:    Toast message displayed when the API fails, this defaults to a generic message to try again. This
//                  may be a string or a function returning a string. The function has the following prototype:
//                      ({method, path, id, params, data, body, error}) => message
//
// The following values are returned from the list API hook:
//
// data:          The data return from the API
// params:        The parameters sent to the backend (as described above)
// setParams:     Used to change the params sent to the backend, changing the params will resend the query to the
//                back end.
// isLoading:     True while the backend is responding to the query
//
// =====================================================================================================================

export const CacheMode = {
  Normal: 'normal',
  Always: 'always',
  Never: 'never',
};

export const useGetAccountingInventoryItems = () => useGetApi('accounting', 'inventoryitems');
export const useGetAccountingRevenueAccounts = () => useGetApi('accounting', 'revenueaccounts');

export const useGetActivityLog = (id) => useGetApi('activitylogs', id);

export const useAnonGetCompany = (id) => useGetApi('guest/company', id);

export const useGetContact = (id) => useGetApi('contacts', id);
export const useListContacts = (params, options) => useListApi('contacts', params, options);

export const useGetCustomer = (id) => useGetApi('customers', id);
export const useFetchCustomer = () => useFetchApi('customers');
export const useSaveCustomer = () => useSaveApi('customers', {successMessage: 'Saved Customer'});
export const useListCustomers = (params, options) => useListApi('customers', params, options);

export const useListDecorations = (params, options = {}) => useListApi('decorations', params, options);
export const useFetchDecoration = () => useFetchApi('decorations');
export const useGetDecorationCategories = () => useListApi('decorations/categories', {}, {enabled: true});

export const useGetIntegration = (id) => useGetApi('integrations', id);
export const useUpdateIntegration = (options) => usePatchApiOld('integrations', options);
export const useGetIntegrationPromoStandardsProgress = (vendorName, params, options) => useGetApiOld(`promo-standards/progress/${vendorName}`, {params, ...options});

export const useUpdateJob = (options) => usePatchApiOld('jobs', options);
export const useGetPaginatedJobs = (params) => useQueryApiOld('jobs', {pageSize: 20, page: 0, sort: {_id: -1}, ...params});
export const useGetJob = (id, params) => useGetApiOld(`jobs/${id}`, {params, enabled: id != null});

export const useGetMarkups = () => useListApi('markups', {}, {enabled: true});

export const useListNotificationTemplates = (params) => useListApi('notification-templates', params);

export const useListProducts = (params, options = {}) => useListApi('products', params, options);
export const useFetchProduct = () => useFetchApi('products');

export const usePurchaseOrderCountForJobVendors = (id) => useGetApiOld(`purchaseOrders/${id}/vendorCounts`);

// TODO: This should use GET
export const useGetReports = (reportName) => usePostApiOld(`reports/${reportName}`, {successMessage: 'Report ready'});

export const useDeleteSalesDoc = (options = {}) => useDeleteApi('salesdocs', options);
export const useFetchSalesDoc = () => useFetchApi(({id, revisionId}) => revisionId ? `salesdocs/${id}/revisions/${revisionId}` : `salesdocs/${id}`);
export const useGetSalesDoc = (idOrNumber) => useGetApi('salesdocs', idOrNumber);
export const useListSalesDocs = (params, options = {}) => useListApi('salesdocs', params, options);
export const useListSalesDocRevisions = (id) => useListApi(`salesdocs/${id}/revisions`, {}, {enabled: !!id});
const saveSalesDocOptions = {successMessage: 'Saved Sales Document'};
export const useSaveSalesDoc = () => useSaveApi('salesdocs', saveSalesDocOptions);
export const useSendSalesDoc = () => useActionApi('salesdocs', 'send');
export const useUpdateSalesDoc = () => useUpdateApi('salesdocs', saveSalesDocOptions);
export const useSalesDocCache = () => useApiCache('salesdocs');

// Anonymous api calls don't need authentication to run
export const useAnonGetSalesDoc = (id) => useGetApi('guest/salesdocs', id);
export const useAnonCommentSalesDoc = () => useActionApi('guest/salesdocs', 'comment');
export const useAnonAcceptSalesDoc = () => useActionApi('guest/salesdocs', 'accept');
export const useAnonMarkAsViewedSalesDoc = () => useActionApi('guest/salesdocs', 'viewed');
export const useAnonSalesDocPaymentIntent = () => useActionApi('guest/salesdocs', 'payment-intent');

// TODO: This should use the list/query API
export const useGetTenants = (params) => useGetApiOld('api/tenants', {params});

export const useListUsers = (params, options) => useListApi('users', params, {enabled: true, ...options});
export const useGetUser = (id) => useGetApi('users', id);
export const useUpdateUser = (options) => usePatchApiOld('users', options);

export const useListVendors = (params, options) => useListApi('vendors', params, {enabled: true, ...options});
export const useFetchVendor = () => useFetchApi('vendors');
export const useSaveVendor = () => useSaveApi('vendors');

export const useGetViews = (entity) => useGetApi('views', entity);
export const useGetViewsCache = (entity) => useApiCache('views', entity);
export const useCreateView = (entity, options) => useCreateApi(`views/${entity}/views`, options);
export const useUpdateView = (entity, options) => useUpdateApi(`views/${entity}/views`, options);
export const useDeleteView = (entity, options) => useDeleteApi(`views/${entity}/views`, options);

export const useCreateViewField = (entity, options) => useCreateApi(`views/${entity}/fields`, options);
export const useUpdateViewField = (entity, options) => useUpdateApi(`views/${entity}/fields`, options);
export const useDeleteViewField = (entity, options) => useDeleteApi(`views/${entity}/fields`, options);
export const useUpdateViewFieldOrder = (entity, options) => useUpdateApi(`views/${entity}`, options);
