import React, {useMemo, useState} from 'react';
import {AccessTime} from '@mui/icons-material';
import {Column, Page, Row} from '../../../componentsLib/Layout';
import {BodyText, HeadingText} from '../../../componentsLib/Text';
import {registerGlobalStyle} from '../../../theme';
import {formatDateLong} from '../../../utils';
import {useParams} from 'react-router';
import {TabPanel, TabbedContent} from '../../../componentsLib/Basic/TabbedContent';
import Files from '../../../modules/file/Files';
import Comments from '../../../componentsOld/Comments/Comments';
import {SalesDoc} from '../Models/SalesDoc';
import {SalesDocTasks} from '../Tabs/SalesDocTasks';
import {SalesDocActivityTab} from '../Tabs/SalesDocActivityTab';
import {SalesDocActionMenuButton} from '../Components/SalesDocActionMenu';
import {SalesDocStatusBar} from '../Components/SalesDocStatusBar';
import {useSalesDocViewState} from '../State/useSalesDocViewState';
import {useCompany} from '../../../hooks';
import {PresentationView} from '../Views/PresentationView';
import {SalesDocRevisionsTab} from '../Tabs/SalesDocRevisionsTab';
import {SalesDocView} from '../Views/SalesDocView';
import {SalesDocSections} from '../Components/SalesDocSection';

registerGlobalStyle('.salesdoc-page', (theme) => ({
  position: 'relative',
  flexGrow: 1,
  '.text-heading': {
    span: {color: theme.colors.text.highlight},
    strong: {color: theme.colors.palette.red}
  },
  '.salesdoc-page-header': {
    '.text-body': {
      fontSize: '1rem',
      span: {color: theme.colors.text.highlight},
    },
    '.warning': {color: theme.colors.palette.red,},
    '.time-icon': {margin: theme.spacing(.5, .5, .5, 2)},
    '.salesdoc-owner': {marginTop: theme.spacing(2)},
  },
  '.tabs': {padding: 0},
  '.hoops-page': {position: 'relative'},
}));

export const SalesDocPage = ({updateField, refetchSalesDocs}) => {
  const {salesDocNumber} = useParams();
  const {salesDoc, refetchSalesDoc} = useSalesDocViewState(salesDocNumber);
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId);
  const [selection, setSelection] = useState({section: SalesDocSections.documentHeader});

  const isPresentation = salesDoc?.documentType === SalesDoc.Type.PRESENTATION || salesDoc?.documentType === SalesDoc.Type.PRESENTATION_TEMPLATE;
  const hideActionButtons = true;

  const context = useMemo(() => ({
    salesDoc,
    company,
    currencySymbol: company.currencySymbol,
    refetchSalesDoc,
    hideActionButtons,
    selection,
    setSelection,
  }), [salesDoc, company, refetchSalesDoc, hideActionButtons, selection]);

  return (
    <Page className={'salesdoc-page'} context={context}>
      {salesDoc &&
        <Column>
          <Row className={'salesdoc-page-header'}>
            <Column fillWidth>
              <Row>
                <HeadingText x24><span>{salesDoc.docTypeName} #{salesDoc.number}</span> {salesDoc.title}</HeadingText>
              </Row>
              <Row>
                <BodyText><span>{salesDoc.customer?.name} | {salesDoc.createdBy?.fullName} </span></BodyText>
                {salesDoc.deadline &&
                  <>
                    <AccessTime fontSize={'small'} className={'warning time-icon'} />
                    <BodyText className={'warning'}> Deadline {formatDateLong(salesDoc.deadline)}</BodyText>
                  </>
                }
              </Row>
              <BodyText className={'salesdoc-owner'}>Owner: {salesDoc.owner?.fullName}</BodyText>
            </Column>
            <Column fillWidth>
              <Row justifyRight>
                <SalesDocActionMenuButton actionButton salesDoc={salesDoc} updateField={updateField} refetchSalesDocs={refetchSalesDocs} />
              </Row>
            </Column>
          </Row>

          <SalesDocStatusBar salesDoc={salesDoc} updateField={updateField} />

          <TabbedContent>
            <TabPanel label='Sale Detail'>
              {!isPresentation && <SalesDocView />}
              {isPresentation && <PresentationView />}
            </TabPanel>
            <TabPanel label='Files'>
              <Files
                customerId={salesDoc.customer?._id}
                getEntityIds={[salesDoc._id]}
                setEntityIds={[salesDoc._id]}
                isWorkable={true}
                createTaskState={{quoteIds: [salesDoc._id], quote: [salesDoc]}}
                showCustomerBtn={true}
                showUploadFileBtn={true}
              />
            </TabPanel>
            <TabPanel label='Comments'>
              <Comments
                channelId={`${salesDoc.documentType}-${salesDoc._id.toString()}`}
                meta={{
                  entityId: salesDoc._id,
                  entityType: salesDoc.documentType,
                  entityName: salesDoc.docTypeName,
                  entityNumber: salesDoc.number,
                }} />
            </TabPanel>
            <TabPanel label='Tasks'>
              <SalesDocTasks salesDoc={salesDoc} />
            </TabPanel>
            <TabPanel label='BackTrack'>
              <SalesDocRevisionsTab salesDoc={salesDoc} />
            </TabPanel>
            <TabPanel label='Activity'>
              <SalesDocActivityTab quote={salesDoc} />
            </TabPanel>
          </TabbedContent>
        </Column>
      }
    </Page>
  );
};
