import {createTheme} from '@material-ui/core/styles';
import {deepmerge} from '@mui/utils';
import {createTheme as createMuiTheme} from '@mui/material/styles';

const typography = {
  fontFamily: [
    '"Rubik"',
    'sans-serif'
  ].join(', '),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 500,
  h1: {fontSize: '2.15rem'}
};

export const palette = {
  text: {primary: '#2F323C'},
  primary: {
    light: '#eafcff',
    main: '#00CCF0',
    dark: '#14AADC',
    contrastText: '#FFFFFF'
  },
  secondary: {
    light: '#E8EAED',
    main: '#D9DADC',
    dark: '#2F323C',
    contrastText: '#FFFFFF',
    hover: '#F3F3F6',
  },
  error: {main: '#FC5F65'},
};

export const colors = {
  red: '#FC5F65',
  redHover: '#CC2F35',
  green: '#22CC96',
  greenDark: '#1EB384',
  orange: '#FFBF66',
  purple: '#9366AF',
  black: '#000000',
  white: '#FFFFFF',
  paper: '#FFFFFF',
  transparent: 'transparent',
  text: '#2F323C',
  contrastText: '#FFFFFF',
  primary: {
    light: '#eafcff',
    main: '#00CCF0',
    dark: '#14AADC',
    text: '#FFFFFF'
  },
  grey: {
    dark: '#2F323C',
    main: '#8F9197',
    light: '#E8EAED',
    subtle: '#D9DADC',
    ultraLight: '#F3F3F6',
    almostWhite: '#FBFBFB',
    logo: '#7A8086',
    bg: '#FAFAFA',
    borderLight: '#F0F0F0',
  }
};

const themeName = 'Hoops Theme';

const overrides = {
  MuiCssBaseline: {
    '@global': {
      html: {
        minHeight: '100%',
        height: '100%',
      },
      body: {
        minHeight: '100%',
        height: '100%',
      },
      '#root': {
        minHeight: '100%',
        height: '100%',
      },
      '.Content': {
        minHeight: '100%',
        height: '100%',
      },

    },
  },

  MuiPickerDTTabs: { // DateTimePicker icons
    tabs: {color: 'white'}
  },
  MuiButton: {
    styleOverrides: {
      contained: {boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 3px, rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.01) 0px -1px 2px'},
      disabled: {boxShadow: 'unset'}
    }
  },
};

const shadows = [
  'none',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.3px rgba(0, 0, 0, 0.028), 0.2px 0.2px 1.1px rgba(0, 0, 0, 0.042), 1px 1px 5px rgba(0, 0, 0, 0.07)',
  '0.1px 0.1px 0.9px rgba(0, 0, 0, 0.04), 0.2px 0.2px 3.1px rgba(0, 0, 0, 0.06), 1px 1px 14px rgba(0, 0, 0, 0.1)',
  '0.1px 0.1px 0.9px rgba(0, 0, 0, 0.061), 0.2px 0.2px 3.1px rgba(0, 0, 0, 0.089), 1px 1px 14px rgba(0, 0, 0, 0.15)',
  '0.1px 0.1px 1.3px rgba(0, 0, 0, 0.081), 0.2px 0.2px 4.5px rgba(0, 0, 0, 0.119), 1px 1px 20px rgba(0, 0, 0, 0.2)',
  '0.1px 0.1px 1.7px rgba(0, 0, 0, 0.101), 0.2px 0.2px 5.6px rgba(0, 0, 0, 0.149), 1px 1px 25px rgba(0, 0, 0, 0.25)',
  '0.1px 0.1px 2px rgba(0, 0, 0, 0.121), 0.2px 0.2px 6.7px rgba(0, 0, 0, 0.179), 1px 1px 30px rgba(0, 0, 0, 0.3)',
  '0.1px 0.1px 2.3px rgba(0, 0, 0, 0.141), 0.2px 0.2px 7.8px rgba(0, 0, 0, 0.209), 1px 1px 35px rgba(0, 0, 0, 0.35)',
  '0.1px 0.1px 2.7px rgba(0, 0, 0, 0.162), 0.2px 0.2px 8.9px rgba(0, 0, 0, 0.238), 1px 1px 40px rgba(0, 0, 0, 0.4)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.1px 0.1px 3px rgba(0, 0, 0, 0.182), 0.2px 0.2px 10.1px rgba(0, 0, 0, 0.268), 1px 1px 45px rgba(0, 0, 0, 0.45)',
  '0.3px 0.4px 5.7px rgba(0, 0, 0, 0.084), 0.8px 0.9px 13.6px rgba(0, 0, 0, 0.121), 1.5px 1.6px 25.7px rgba(0, 0, 0, 0.15), 2.7px 2.9px 45.8px rgba(0, 0, 0, 0.179), 5px 5.4px 85.6px rgba(0, 0, 0, 0.216), 12px 13px 205px rgba(0, 0, 0, 0.3)'
];

export const themeOptions = {overrides, palette, typography, themeName, colors, shadows};

export default createTheme(themeOptions);
export const muiTheme = createMuiTheme(deepmerge(themeOptions));
