import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {Page, Row} from '../../../componentsLib/Layout';
import {useCompany, useSnackbar} from '../../../hooks';
import {useAnonGetCompany, useAnonCommentSalesDoc, useAnonMarkAsViewedSalesDoc, useAnonAcceptSalesDoc} from '../../../hooks/api';
import {registerGlobalStyle} from '../../../theme';
import {useSalesDocGuestState} from '../State/useSalesDocGuestState';
import {PresentationView} from '../Views/PresentationView';
import {PdfPrintService} from '../../../services';
import {AttentionConfirmDialog} from '../../../componentsLib/Popovers';
import {BodyText} from '../../../componentsLib/Text';
import Lottie from 'react-lottie';
import successAlertIcon from '../../../assets/lotties/success-alert-icon.json';
import {SalesDocView} from '../Views/SalesDocView';
import {BusySpinner} from '../../../componentsLib/Basic';
import {SalesDocSections} from '../Components/SalesDocSection';

registerGlobalStyle('.sales-guest-page', (theme) => ({
  '.text.text-body': {width: '100%'},
  '.salesdoc-view': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.loading-row': {height: '100%'},
  '&.presentation': {
    background: theme.colors.background.white,
    display: 'flex',
    justifyContent: 'center',
    minWidth: 300,
    '.page-scroll-container': {
      display: 'flex',
      justifyContent: 'center'
    },
    '.presentation-view': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 1420,
      '.product-gallery': {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1),
        paddingLeft: 0
      }
    },
  }
}));

registerGlobalStyle('.salesdoc-accept-comment-success-dialog', (theme) => ({
  '.MuiDialog-paper.MuiPaper-root': {maxWidth: 400},
  svg: {marginTop: 0},
  '.modal-dialog-content': {
    '&>.text-body': {
      fontSize: '1rem',
      padding: theme.spacing(0, 5, 2.5),
    },
  },
}));

export function SalesDocGuestPage({salesDocId: salesDocIdProp, hideActionButtons, printPdf, onPrintPdfComplete}) {
  const {salesDocId: salesDocIdParam} = useParams();
  const {salesDoc, isLoading: salesDocLoading, refetchSalesDoc} = useSalesDocGuestState(salesDocIdProp ?? salesDocIdParam);
  const {data} = useAnonGetCompany(salesDoc?.companyId);
  const {accept: acceptSalesDocApi} = useAnonAcceptSalesDoc();
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId, data.company);
  const [openSuccessDialog, setOpenSuccessDialog] = useState({open: false, type: ''});
  const {comment: commentSalesDocAPI, isLoading: commentSaving} = useAnonCommentSalesDoc();
  const {viewed: markAsViewedSalesDocAPI} = useAnonMarkAsViewedSalesDoc();
  const [pdfLoading, setPdfLoading] = useState(false);
  const snackbar = useSnackbar();
  const [commentText, setCommentText] = useState('');
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const salesDocPdf = useRef(null);
  const [selection, setSelection] = useState({section: SalesDocSections.documentHeader});

  const isBuilder = false;
  const isPresentation = salesDoc?.isPresentation();
  const isGuestView = !company._id;

  const handleDownloadPdf = useCallback(async () => {
    if (salesDocPdf.current) {
      setShowComment(false);
      setPdfLoading(true);
      const pdf = await PdfPrintService.print(salesDocPdf.current);
      if (!document.body.contains(salesDocPdf.current)) {
        snackbar.showSnackbarError('PDF download canceled');
      } else if (pdf) {
        await pdf.save(`${salesDoc.docTypeName}-${salesDoc.number}`);
      } else {
        snackbar.showSnackbarError();
      }
      setPdfLoading(false);
    }
  }, [salesDoc, snackbar]);

  useEffect(() => {
    (async () => {
      if (printPdf && salesDocPdf.current) {
        await handleDownloadPdf();
        onPrintPdfComplete();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc]);

  useEffect(() => {
    if (salesDoc?._id && isGuestView) {
      markAsViewedSalesDocAPI({id: salesDoc?._id}, {successMessage: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc]);

  const handleAccept = useCallback(() => {
    salesDoc.accept();
    acceptSalesDocApi({id: salesDoc?._id}, {successMessage: false});
    //success dialog
    setOpenSuccessDialog({open: true, type: 'accept'});
  }, [salesDoc, acceptSalesDocApi]);

  const handlePaymentSuccess = useCallback(() => {
    salesDoc.accept();
    refetchSalesDoc();
  }, [refetchSalesDoc, salesDoc]);

  const saveComment = useCallback(async ({groupId}) => {
    const firstItem = salesDoc.getItemsInGroup(groupId)[0];
    const res = await commentSalesDocAPI(
      {id: salesDoc?._id, commentText: groupId ? `Re: ${firstItem.name} - ${commentText}` : commentText},
      {
        successMessage: false,
        errorMessage: ({message}) => message,
      }
    );
    if (res) {
      //success dialog
      setOpenSuccessDialog({open: true, type: 'comment'});
      setShowComment(false);
      setCommentText('');
    }
  }, [salesDoc, commentText, commentSalesDocAPI]);

  const context = useMemo(
    () => ({
      salesDoc,
      company,
      refetchSalesDoc,
      isBuilder,
      hideActionButtons,
      printPdf,
      onPrintPdfComplete,
      handleDownloadPdf,
      pdfLoading,
      setShowComment,
      handleAccept,
      isPaymentDialogOpen,
      setIsPaymentDialogOpen,
      handlePaymentSuccess,
      showComment,
      commentText,
      setCommentText,
      saveComment,
      commentSaving,
      salesDocPdf,
      selection,
      setSelection,
    }),
    [
      company,
      salesDoc,
      refetchSalesDoc,
      isBuilder,
      hideActionButtons,
      printPdf,
      onPrintPdfComplete,
      handleDownloadPdf,
      pdfLoading,
      setShowComment,
      handleAccept,
      isPaymentDialogOpen,
      setIsPaymentDialogOpen,
      handlePaymentSuccess,
      showComment,
      commentText,
      setCommentText,
      saveComment,
      commentSaving,
      salesDocPdf,
      selection,
      setSelection,
    ]
  );

  return (
    <Page className={['sales-guest-page', isPresentation && 'presentation']} context={context}>
      {!salesDoc && salesDocLoading &&
        <Row justifyCenter alignCenter className={'loading-row'}>
          <BusySpinner large center message={'Loading'} />
        </Row>
      }
      {salesDoc && !isPresentation && <SalesDocView />}
      {salesDoc && isPresentation && <PresentationView />}
      {salesDoc && openSuccessDialog.open && (
        <AttentionConfirmDialog
          title={openSuccessDialog.type === 'accept' ? `${salesDoc.docTypeName} Accepted` : 'Comment Sent'}
          onClose={() => setOpenSuccessDialog({open: false})}
          onOk={() => setOpenSuccessDialog({open: false})}
          okText={'Ok'}
          cancelText={null}
          className={'salesdoc-accept-comment-success-dialog'}
        >
          {openSuccessDialog.type === 'accept' && <BodyText text={`You accepted ${salesDoc.docTypeName} ${salesDoc.number}.`} />}
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: successAlertIcon,
              rendererSettings: {preserveAspectRatio: 'xMidYMid slice'},
            }}
            height={200}
            width={200}
          />
          {openSuccessDialog.type === 'accept' && <BodyText text={'Thank you for your business, we\'ll be in touch with further updates'} />}
          {openSuccessDialog.type === 'comment' && <BodyText text={'Your comment was successfully added, our team will be notified'} />}
        </AttentionConfirmDialog>
      )}
    </Page>
  );
}
