import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Typography} from '@mui/material';
import classNames from 'classnames';
import {asCurrencyString, sortSizes} from '../../utils';
import {useDebugLogChanges} from '../../hooks/useDebugLogChanges';
import {BoxGrid, Column, ExpansionButton, ExpansionPanel, ExpansionPanelProvider, Row} from '../../componentsLib/Layout';
import {AngleText} from '../../componentsLib/Text';
import {hoopsTheme} from '../../theme';

const styles = {
  '& .price-break-table': {
    gridGap: 0,
    border: `1px solid ${hoopsTheme.colors.border.main}`,
    alignSelf: 'start',
    '&>*': {
      padding: 1,
      height: '100%',
      cursor: 'pointer',
      border: `0 solid ${hoopsTheme.colors.border.main}`,
      '&:not(:first-child)': {borderLeftWidth: 1},
      '&:hover': {backgroundColor: hoopsTheme.colors.background.hover,},
      '&.current': {color: hoopsTheme.colors.text.highlight},
    },
  },
  '& .variant-table': {
    padding: '1px',
    gridGap: 0,
    position: 'relative',
    '&>*': {
      padding: 1,
      height: '100%',
      cursor: 'pointer',
      border: `1px solid ${hoopsTheme.colors.border.main}`,
      '&:not(.last-column)': {borderRightWidth: 0},
      '&:not(.last-row)': {borderBottomWidth: 0},
      '&.hover-color': {backgroundColor: hoopsTheme.colors.background.hover,},
      '&.hover-size': {backgroundColor: hoopsTheme.colors.background.hover,},
    },
    '&>*:first-child': {
      background: 'transparent',
      borderColor: hoopsTheme.colors.transparent,
      minWidth: '100px',
      cursor: 'default',
    },
  },
  '& .variant-price-table-header p': {
    color: hoopsTheme.colors.text.highlight,
    paddingRight: '4px',
  }
};

export function ProductVariantPriceTable({product, priceBreak: initialPriceBreak, onHoverVariant}) {
  const [currentPriceBreak, setCurrentPriceBreak] = useState(initialPriceBreak ?? 0);
  const [hoverColor, setHoverColor] = useState(null);
  const [hoverSize, setHoverSize] = useState(null);

  useEffect(() => {
    setCurrentPriceBreak(initialPriceBreak);
  }, [initialPriceBreak]);

  // Build a grid of colors/sizes/price breaks
  // colorSizes[colorName][sizeName] - contains each variant
  // variantSizes - a list of size names, sorted by size
  // variantColors - a list of color names, sorted alphabetically
  // priceBreaks - list of quantities where the price changes
  // colorImages - map from color name to image
  const {colorSizes, variantSizes, variantColors, priceBreaks} = useMemo(() => {
    const priceBreakSet = new Set();
    const sizeSet = new Set();
    const colorSizeTable = {};
    if (product?.variants) {
      product.variants.forEach((variant) => {
        if (colorSizeTable[variant.color.name] == null) {
          colorSizeTable[variant.color.name] = {};
        }
        colorSizeTable[variant.color.name][variant.size.name] = variant;
        sizeSet.add(variant.size.name);
        variant.priceBreaks.forEach((pb) => priceBreakSet.add(pb.quantity));
      });
    }
    return {
      colorSizes: colorSizeTable,
      variantSizes: sortSizes(sizeSet.values()),
      variantColors: [...Object.keys(colorSizeTable)].sort(),
      priceBreaks: [...priceBreakSet].sort((a, b) => (+a) - (+b)),
    };
  }, [product?.variants]);

  const quantity = priceBreaks[currentPriceBreak] ?? priceBreaks[0];
  const getPrice = ({color, size}) => {
    const pb = colorSizes[color]?.[size]?.priceBreaks?.findLast(({quantity: q}) => quantity >= q);
    if (pb) {
      return asCurrencyString(pb.price);
    }
    return '-';
  };

  const handleHover = (color, size) => {
    setHoverColor(color);
    setHoverSize(size);
    onHoverVariant(color, size);
  };

  useDebugLogChanges('ProductVariantPriceTable', {
    product,
    initialPriceBreak,
    colorSizes,
    variantSizes,
    variantColors,
    priceBreaks,
    currentPriceBreak,
    hoverColor,
    hoverSize
  });

  return (
    <Column className={'variant-price-table'} sx={styles} scroll>
      <ExpansionPanelProvider initShown={false}>
        <Row noSpace className={'variant-price-table-header'}>
          <Typography variant='body1'>Color Size Price Table</Typography>
          <ExpansionButton/>
        </Row>
        <ExpansionPanel>
          <Column>
            {priceBreaks.length > 1 &&
              <Row className={'price-break-table'}>
                {priceBreaks.map((pb, index) => (
                  <Typography key={pb} variant={'body2'} className={classNames([index === currentPriceBreak && 'current'])}
                              onClick={() => setCurrentPriceBreak(index)}>
                    {index + 1 < priceBreaks.length ? `${pb} - ${priceBreaks[index + 1] - 1}` : `${pb}+`}
                  </Typography>
                ))}
              </Row>
            }
            <BoxGrid className={'variant-table'} gridTemplateColumns={`max-content repeat(${variantColors.length}, 1fr)`}
                     onMouseLeave={() => handleHover(null, null)}>
              <AngleText angle={-30} variant={'body2'} onMouseEnter={() => handleHover(null, null)}></AngleText>
              {variantColors.map((color, index) => (
                <AngleText
                  key={color}
                  className={classNames(['heading', color === hoverColor && 'hover-color', index === variantColors.length - 1 && 'last-column'])}
                  angle={-30}
                  variant={'body2'}
                  onMouseEnter={() => handleHover(color, null)}
                >
                  {color}
                </AngleText>
              ))}
              {variantSizes.map((size, sIndex) => (
                <Fragment key={size}>
                  <Typography
                    className={classNames([size === hoverSize && 'hover-size', sIndex === variantSizes.length - 1 && 'last-row'])}
                    variant={'body2'}
                    onMouseEnter={() => handleHover(null, size)}
                  >
                    {size}
                  </Typography>
                  {variantColors.map((color, cIndex) => (
                    <Typography
                      key={color}
                      className={classNames([
                        color === hoverColor && 'hover-color',
                        size === hoverSize && 'hover-size',
                        cIndex === variantColors.length - 1 && 'last-column',
                        sIndex === variantSizes.length - 1 && 'last-row'
                      ])}
                      variant={'body2'}
                      onMouseEnter={() => handleHover(color, size)}
                    >
                      {getPrice({color, size})}
                    </Typography>
                  ))}
                </Fragment>
              ))}
            </BoxGrid>
          </Column>
        </ExpansionPanel>
      </ExpansionPanelProvider>
    </Column>
  );
}

export const MemoizedProductVariantPriceTable = React.memo(ProductVariantPriceTable);
