import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Button} from '../../componentsLib/Basic';
import {
  AutoCompleteContainer,
  AutoCompleteEndButtons,
  AutoCompleteHeading,
  AutoCompleteList,
  AutoCompleteListItem,
  AutoCompletePopover,
  AutoCompleteSearchBar,
  AutoCompleteTextInput
} from '../../componentsLib/Popovers';
import {HoopsPropTypes} from '../../componentsLib';
import {useListDecorations} from '../../hooks/api';
import {Typography} from '@material-ui/core';
import {Column, Row} from '../../componentsLib/Layout';
import Avatar from '@material-ui/core/Avatar';
import {initials, listToMap} from '../../utils';
import {registerGlobalStyle} from '../../theme';
import {Tabs} from '../../componentsLib/Basic/Tabs';

registerGlobalStyle('.decoration-autocomplete-popover', (theme) => ({
  '.MuiAvatar-root': {backgroundColor: theme.colors.palette.orange},
  '.tabs': {paddingTop: theme.spacing(.25)}
}));

export function DecorationAutoComplete({
                                         className,
                                         catalog = true,
                                         decoration: initDecoration,
                                         products = null,
                                         search: initSearch,
                                         onAddNewDecoration,
                                         onChange,
                                         onSearchChange,
}) {
  const [currentDecoration, setDecoration] = useState(initDecoration);
  const [search, setSearch] = useState(initSearch);
  const [selectedTab, setSelectedTab] = useState(0);
  const {data: {decorations: catalogDecorations}, isLoading: isLoadingCatalog} = useListDecorations({search: search ?? ''}, {enabled: catalog});
  const {data: {decorations: linkedDecorations}, isLoading: isLoadingLinked} = useListDecorations(
    {search: search ?? '', query: {productId: {$in: products?.map((product) => product._id)}}},
    {enabled: products != null && products.length > 0}
  );
  const isLoading = isLoadingCatalog || isLoadingLinked;

  useEffect(() => {
    setDecoration(initDecoration);
  }, [initDecoration]);

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  const handleChange = useCallback((_id) => {
    const newDecoration = catalogDecorations?.find((deco) => deco._id === _id) ?? linkedDecorations?.find((deco) => deco._id === _id);
    if (newDecoration) {
      setDecoration(newDecoration);
      if (currentDecoration !== newDecoration) {
        onChange?.(newDecoration);
      }
    }
  }, [catalogDecorations, currentDecoration, linkedDecorations, onChange]);

  const handleSearchChange = useCallback((e) => {
    const newSearch = e.target.value;
    if (newSearch !== search) {
      onSearchChange(newSearch);
    }
  }, [onSearchChange, search]);

  const productMap = useMemo(() => listToMap(products, '_id'), [products]);

  const linkedSorted = useMemo(() => {
    if (!linkedDecorations) {
      return [];
    }

    // Have to sort the linked decorations by product, but, we don't want to lose the ordering they were returned
    // because that is ordered by best match for the search term
    const groups = linkedDecorations.reduce((acc, deco) => ({...acc, [deco.productId]: [...(acc[deco.productId] ?? []), deco]}), {});
    const sortedProducts = Object.keys(groups).sort((a, b) => productMap[a].title.localeCompare(productMap[b].title));
    return sortedProducts.reduce((acc, prodId) => [...acc, ...groups[prodId]], []);
  }, [linkedDecorations, productMap]);

  return (
    <AutoCompleteContainer
      className={[className, 'decoration-autocomplete']}
      value={currentDecoration?._id}
      search={search}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      loading={isLoading}
    >
      <AutoCompleteTextInput value={currentDecoration?.name}/>
      <AutoCompletePopover className={'decoration-autocomplete-popover'}>
        <AutoCompleteSearchBar search={search}/>
        {catalogDecorations && linkedDecorations &&
          <Tabs tabs={['Hoops Catalog', 'Linked To Product']} value={selectedTab} onChange={setSelectedTab}/>
        }
        <AutoCompleteList>
          {(selectedTab === 0 || linkedDecorations == null) && catalogDecorations && catalogDecorations.length > 0 &&
            catalogDecorations?.map((decoration) => (
              <DecorationAutoCompleteItem key={decoration._id} decoration={decoration}/>
            ))
          }
          {(selectedTab === 1 || catalogDecorations == null) && linkedDecorations && linkedDecorations.length > 0 &&
            linkedSorted?.map((decoration, index, list) => (
              <Fragment key={decoration._id}>
                {(index === 0 || list[index - 1]?.productId !== decoration.productId) &&
                  <AutoCompleteHeading heading={productMap[decoration.productId].title}/>
                }
                <DecorationAutoCompleteItem decoration={decoration}/>
              </Fragment>
            ))
          }
        </AutoCompleteList>
        <AutoCompleteEndButtons>
          <Button navPrimary onClick={onAddNewDecoration}>Add New Decoration</Button>
        </AutoCompleteEndButtons>
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

DecorationAutoComplete.propTypes = {
  className: HoopsPropTypes.className,
  catalog: HoopsPropTypes.bool,
  decoration: HoopsPropTypes.object,
  products: HoopsPropTypes.arrayOf(HoopsPropTypes.object),
  search: HoopsPropTypes.string,
  onAddNewDecoration: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onSearchChange: HoopsPropTypes.func,
};

function DecorationAutoCompleteItem({decoration}) {
  return (
    <AutoCompleteListItem key={decoration._id} value={decoration._id}>
      <Row gap>
        <Avatar>
          {initials(decoration.name)}
        </Avatar>
        <Column className={'decoration-details'}>
          <Typography className={'title'}>
            {decoration.name}
          </Typography>
          <Typography className={'details'}>
            <span>Vendor: </span>{decoration.vendorName}
            <span> | Category: </span>{decoration.category}
          </Typography>
        </Column>
      </Row>
    </AutoCompleteListItem>
  );
}
