import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {HoopsButton} from '../../componentsLib';
import {ReportContext} from './Reporting';
import {asCurrencyStringCommaSeparated, formatIso, formatDateShort} from '../../utils';
import store from '../../store';
import {useGetTenants} from '../../hooks/api';
import {Box} from '@mui/system';
import '../../assets/scss/print.scss';
import qs from 'qs';

export const RoyaltyReport = ({
  currencySymbol,
  reportSettings,
}) => {
  const {data} = useContext(ReportContext);
  const {jobsEligibleForRoyalties, jobsVoided, jobsInProgress, jobsCredits, jobsCreditsDeleted, jobsAccountingError} = data?.rows ?? [];
  const footerStyles = {whiteSpace: 'nowrap', fontWeight: 500};

  const handlePrint = () => {
    let printContents = document.getElementById('printableArea').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();
  };

  const handleDownload = () => {
    const csvPath = `${process.env.REACT_APP_BASE_URL}/rest/reports/fullypromoted/csv/royalty?${qs.stringify({startDate: formatIso(reportSettings.startDate), endDate: formatIso(reportSettings.endDate)})}&tenantId=${reportSettings.tenantId}`;
    const link = document.createElement('a');
    link.download = 'royalty.csv';
    link.href = csvPath;
    link.click();
  };

  return (
    <Grid item xs={12}>
      <Grid container id='printableArea'>
        <Grid item xs={6}>
          <Typography variant={'h5'}>Royalty Report</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display='flex' justifyContent='flex-end' className='noPrint'>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={handlePrint}
              sx={{whiteSpace: 'nowrap', mr: 1}}
            >
              Print
            </HoopsButton>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={handleDownload}
              sx={{whiteSpace: 'nowrap'}}
            >
              Download CSV
            </HoopsButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h4'}>{data?.summary?.tenantName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h6'} mt={4}>Summary</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell variant={'head'}>Ending On Date</TableCell>
                <TableCell colSpan='4' align={'right'}>{formatDateShort(reportSettings.endDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Report Run Date</TableCell>
                <TableCell colSpan='4' align={'right'}>{formatDateShort(new Date())}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Total Invoices</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsEligibleForRoyalties?.totalSalesMinusTaxes)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Minus Voided Jobs</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsVoided?.totalVoidedAmountMinusTaxes)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Minus Shipping Charges</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.shippingTotal)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Minus Credits Not Yet Reported</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsCredits?.totalCredits)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Add Deleted Credits</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsCreditsDeleted?.totalCredits)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Royalty Base</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.royaltyBase)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Royalty Cap</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.royaltyCap)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Royalty Percentage</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${asCurrencyStringCommaSeparated(data?.summary?.royaltyPercentage)}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Royalty Fee</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.royaltyFee)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Marketing Fee </TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.marketingFee)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Total Due</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.totalDue)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}></TableCell>
                <TableCell colSpan='4' align={'right'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Royalty Amount Without Cap</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.royaltyWithoutCap)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>You Saved</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.savings)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Your Royalty % After Cap</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${asCurrencyStringCommaSeparated(data?.summary?.royaltyAfterCap)}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Number of Voided Invoices</TableCell>
                <TableCell colSpan='4' align={'right'}>{data?.rows?.jobsVoided?.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Value Current Voided Invoices</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsVoided?.totalSalesMinusTaxes)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant={'head'}>Value Work In Progress</TableCell>
                <TableCell colSpan='4' align={'right'}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsInProgress?.totalSalesMinusTaxes)}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        {/* Jobs Eligible for Royalties */}
        <Grid item xs={12} sx={{mt: 2}}>
          <Typography variant='h6'>{`Invoiced Jobs from ${formatDateShort(reportSettings.startDate)} thru ${formatDateShort(reportSettings.endDate)}`}</Typography>
        </Grid>
        {jobsEligibleForRoyalties?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {Object.entries(jobsEligibleForRoyalties[0]).map((object, idx) => {
                    if (typeof object[1] === 'number') {
                      return (<TableCell align='right' key={idx}>{object[0]}</TableCell>);
                    }
                    return (<TableCell key={idx}>{object[0]}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsEligibleForRoyalties?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (typeof object[1] === 'number') {
                        if (object[0] === 'Value Eligible For Royalties (Ex Tax)' && row['Previous Invoice Value (Ex Tax)'] > 0) {
                          return (<TableCell align='right' sx={{whiteSpace: 'nowrap'}}><em>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</em></TableCell>);
                        }
                        return (<TableCell align='right' sx={{whiteSpace: 'nowrap'}}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      if (['Job Created Date', 'Invoice Date', 'Void Date', 'Invoice Updated Date'].includes(object[0])) {
                        return (<TableCell sx={{whiteSpace: 'nowrap'}}>{object[1] ? formatDateShort(object[1]) : '-'}</TableCell>);
                      }
                      return (<TableCell key={idx} sx={[0].includes(idx) && {whiteSpace: 'nowrap'}}>{object[1] ?? '-'}</TableCell>);
                    })}
                  </TableRow>
                )}
                <TableRow>
                  {data?.summary?.jobsEligibleForRoyalties.tableTotals.map((el, idx) => {
                    if (idx === 0) {
                      return (<TableCell key={idx} colSpan={2} sx={footerStyles}>{el}</TableCell>);
                    }
                    if (typeof el === 'number') {
                      return (<TableCell align='right' key={idx} sx={footerStyles}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(el)}`}</TableCell>);
                    }
                    return (<TableCell sx={footerStyles} key={idx}>{el}</TableCell>);
                  })}
                </TableRow>
              </TableBody>
            </Table>
            {/* Summary */}
            <Table size='small' sx={{maxWidth: 400, mt: 3, mb: 3, border: '1px solid #E0E0E0'}}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Summary:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Sales (Ex Tax)</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsEligibleForRoyalties?.totalSalesMinusTaxes)}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Shipping Fees</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsEligibleForRoyalties?.shipping)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          : <Grid item xs={12}>No records</Grid>
        }

        {/* Voided Jobs */}
        <Grid item xs={12}>
          <Typography variant='h6'>{`Voided from ${formatDateShort(reportSettings.startDate)} thru ${formatDateShort(reportSettings.endDate)}`}</Typography>
        </Grid>
        {jobsVoided?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small' sx={{'& .MuiTableCell-head': {whiteSpace: 'nowrap'}, '& .MuiTableCell-body': {whiteSpace: 'nowrap'}}}>
              <TableHead>
                <TableRow>
                  {Object.entries(jobsVoided[0]).map((object, idx) => {
                    if (typeof object[1] === 'number') {
                      return (<TableCell align='right' key={idx}>{object[0]}</TableCell>);
                    }
                    return (<TableCell key={idx}>{object[0]}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsVoided?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (typeof object[1] === 'number') {
                        return (<TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      if (['Job Created Date', 'Invoice Date', 'Invoice Updated Date', 'Void Date'].includes(object[0])) {
                        return (<TableCell>{object[1] ? formatDateShort(object[1]) : ''}</TableCell>);
                      }
                      return (<TableCell key={idx}>{object[1]}</TableCell>);
                    })}
                  </TableRow>
                )}
                <TableRow>
                  {data?.summary?.jobsVoided.tableTotals.map((el, idx) => {
                    if (idx === 0) {
                      return (<TableCell key={idx} colSpan={2} sx={footerStyles}>{el}</TableCell>);
                    }
                    if (typeof el === 'number') {
                      return (<TableCell align='right' key={idx} sx={footerStyles}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(el)}`}</TableCell>);
                    }
                    return (<TableCell sx={footerStyles} key={idx}>{el}</TableCell>);
                  })}
                </TableRow>
              </TableBody>
            </Table>
            {/* Summary */}
            <Table size='small' sx={{maxWidth: 400, mt: 3, mb: 3, border: '1px solid #E0E0E0'}}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Summary:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Sales (Ex Tax)</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsVoided?.totalSalesMinusTaxes)}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Shipping Fees</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsVoided?.shipping)}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Voided Amount</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsVoided?.totalVoidedAmountMinusTaxes)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid >
          : <Grid item xs={12}>No records</Grid>
        }

        {/* Work in Progress */}
        <Grid item xs={12}>
          <Typography variant='h6'>{'Work in progress'}</Typography>
        </Grid>
        {jobsInProgress?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small' sx={{'& .MuiTableCell-head': {whiteSpace: 'nowrap'}, '& .MuiTableCell-body': {whiteSpace: 'nowrap'}}}>
              <TableHead>
                <TableRow>
                  {Object.entries(jobsInProgress[0]).map((object, idx) => {
                    if (typeof object[1] === 'number') {
                      return (<TableCell align='right' key={idx}>{object[0]}</TableCell>);
                    }
                    return (<TableCell key={idx}>{object[0]}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsInProgress?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (typeof object[1] === 'number') {
                        return (<TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      if (['Job Created Date', 'Invoice Date', 'Void Date'].includes(object[0])) {
                        return (<TableCell>{object[1] ? formatDateShort(object[1]) : ''}</TableCell>);
                      }
                      return (<TableCell sx={{whiteSpace: 'nowrap'}} key={idx}>{object[1]}</TableCell>);
                    })}
                  </TableRow>
                )}
                <TableRow>
                  {data?.summary?.jobsInProgress.tableTotals.map((el, idx) => {
                    if (idx === 0) {
                      return (<TableCell key={idx} colSpan={2} sx={footerStyles}>{el}</TableCell>);
                    }
                    if (typeof el === 'number') {
                      return (<TableCell align='right' key={idx} sx={footerStyles}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(el)}`}</TableCell>);
                    }
                    return (<TableCell key={idx} sx={footerStyles}>{el}</TableCell>);
                  })}
                </TableRow>
              </TableBody>
            </Table>
            {/* Summary */}
            <Table size='small' sx={{maxWidth: 400, mt: 3, mb: 3, border: '1px solid #E0E0E0'}}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Summary:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Sales (Ex Tax)</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsInProgress?.totalSalesMinusTaxes)}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Shipping Fees</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsInProgress?.shipping)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid >
          : <Grid item xs={12}>No records</Grid>
        }

        {/* Credits */}
        <Grid item xs={12}>
          <Typography variant='h6'>{`Credits issued from ${formatDateShort(reportSettings.startDate)} thru ${formatDateShort(reportSettings.endDate)}`}</Typography>
        </Grid>
        {jobsCredits?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small' sx={{'& .MuiTableCell-head': {whiteSpace: 'nowrap'}, '& .MuiTableCell-body': {whiteSpace: 'nowrap'}}}>
              <TableHead>
                <TableRow>
                  {Object.entries(jobsCredits[0]).map((object, idx) => {
                    if (typeof object[1] === 'number') {
                      return (<TableCell align='right' key={idx}>{object[0]}</TableCell>);
                    }
                    return (<TableCell key={idx}>{object[0]}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsCredits?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (typeof object[1] === 'number') {
                        return (<TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      if (['Credit Created Date'].includes(object[0])) {
                        return (<TableCell>{object[1] ? formatDateShort(object[1]) : ''}</TableCell>);
                      }
                      return (<TableCell key={idx}>{object[1]}</TableCell>);
                    })}
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {data?.summary?.jobsCredits.tableTotals.map((el, idx) => {
                    if (idx === 0) {
                      return (<TableCell key={idx} colSpan={2}>{el}</TableCell>);
                    }
                    if (typeof el === 'number') {
                      return (<TableCell align='right' key={idx}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(el)}`}</TableCell>);
                    }
                    return (<TableCell key={idx}>{el}</TableCell>);
                  })}
                </TableRow>
              </TableFooter>
            </Table>
            {/* Summary */}
            <Table size='small' sx={{maxWidth: 400, mt: 3, mb: 3, border: '1px solid #E0E0E0'}}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Summary:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Credits</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsCredits?.totalCredits)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid >
          : <Grid item xs={12}>No records</Grid>
        }

        {/* Credits Deleted*/}
        <Grid item xs={12}>
          <Typography variant='h6'>{`Credits Deleted from ${formatDateShort(reportSettings.startDate)} thru ${formatDateShort(reportSettings.endDate)}`}</Typography>
        </Grid>
        {jobsCreditsDeleted?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small' sx={{'& .MuiTableCell-head': {whiteSpace: 'nowrap'}, '& .MuiTableCell-body': {whiteSpace: 'nowrap'}}}>
              <TableHead>
                <TableRow>
                  {Object.entries(jobsCreditsDeleted[0]).map((object, idx) => {
                    if (typeof object[1] === 'number') {
                      return (<TableCell align='right' key={idx}>{object[0]}</TableCell>);
                    }
                    return (<TableCell key={idx}>{object[0]}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsCreditsDeleted?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (typeof object[1] === 'number') {
                        return (<TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      if (['Credit Created Date'].includes(object[0])) {
                        return (<TableCell>{object[1] ? formatDateShort(object[1]) : ''}</TableCell>);
                      }
                      return (<TableCell key={idx}>{object[1]}</TableCell>);
                    })}
                  </TableRow>
                )}
                <TableRow>
                  {data?.summary?.jobsCreditsDeleted.tableTotals.map((el, idx) => {
                    if (idx === 0) {
                      return (<TableCell key={idx} colSpan={2} sx={footerStyles}>{el}</TableCell>);
                    }
                    if (typeof el === 'number') {
                      return (<TableCell align='right' key={idx} sx={footerStyles}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(el)}`}</TableCell>);
                    }
                    return (<TableCell key={idx} sx={footerStyles}>{el}</TableCell>);
                  })}
                </TableRow>
              </TableBody>
            </Table>
            {/* Summary */}
            <Table size='small' sx={{maxWidth: 400, mt: 3, mb: 3, border: '1px solid #E0E0E0'}}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Summary:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Credits</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsCreditsDeleted?.totalCredits)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid >
          : <Grid item xs={12}>No records</Grid>
        }

        {/* Jobs with mismatched invoice compared to accounting total */}
        <Grid item xs={12} sx={{mt: 2}}>
          <Typography variant='h6'>{`Hoops Invoice Does Not Match Invoice in Accounting Package from ${formatDateShort(reportSettings.startDate)} thru ${formatDateShort(reportSettings.endDate)}`}</Typography>
        </Grid>
        {jobsAccountingError?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small' sx={{'& .MuiTableCell-head': {whiteSpace: 'nowrap'}, '& .MuiTableCell-body': {whiteSpace: 'nowrap'}}}>
              <TableHead>
                <TableRow>
                  {Object.entries(jobsAccountingError[0]).map((object, idx) => {
                    if (typeof object[1] === 'number') {
                      return (<TableCell align='right' key={idx}>{object[0]}</TableCell>);
                    }
                    return (<TableCell key={idx}>{object[0]}</TableCell>);
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsAccountingError?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (typeof object[1] === 'number') {
                        return (<TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      if (['Job Created Date'].includes(object[0])) {
                        return (<TableCell>{object[1] ? formatDateShort(object[1]) : ''}</TableCell>);
                      }
                      return (<TableCell key={idx}>{object[1]}</TableCell>);
                    })}
                  </TableRow>
                )}
                <TableRow>
                  {data?.summary?.jobsAccountingError.tableTotals.map((el, idx) => {
                    if (idx === 0) {
                      return (<TableCell key={idx} colSpan={2} sx={footerStyles}>{el}</TableCell>);
                    }
                    if (typeof el === 'number') {
                      return (<TableCell align='right' key={idx} sx={footerStyles}>{`${currencySymbol} ${asCurrencyStringCommaSeparated(el)}`}</TableCell>);
                    }
                    return (<TableCell key={idx} sx={footerStyles}>{el}</TableCell>);
                  })}
                </TableRow>
              </TableBody>
            </Table>
            {/* Summary */}
            <Table size='small' sx={{maxWidth: 400, mt: 3, mb: 3, border: '1px solid #E0E0E0'}}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    Summary:
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Mismatched Sales</TableCell>
                  <TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(data?.summary?.jobsAccountingError?.differenceTotal)}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          : <Grid item xs={12}>No records</Grid>
        }
      </Grid>
    </Grid >
  );
};

export const RoyaltyReportSettings = () => {
  const {setReportSettings, reportSettings, getReportApi, isLoading} = useContext(ReportContext);
  const company = store.getState()?.companyReducer.company;
  reportSettings.tenantId ?? setReportSettings({tenantId: company._id});
  const {data: tenants} = useGetTenants({skip: 0, limit: 200, isPaged: false});
  const sortedTenants = tenants?.items?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                  <KeyboardDatePicker name='startDate' required label='Start Date' fullWidth={true} value={reportSettings.startDate} onChange={(value) => setReportSettings({startDate: formatIso(value)})} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <KeyboardDatePicker name='endDate' required label='End Date' fullWidth={true} value={reportSettings.endDate} onChange={(value) => setReportSettings({endDate: formatIso(value)})} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <RadioGroup defaultValue={sortedTenants[0]?._id ?? null} onChange={(e) => setReportSettings({tenantId: e.target.value})}>
                    {sortedTenants?.map((tenant, index) => (
                      <FormControlLabel
                        key={index}
                        value={tenant._id}
                        control={<Radio color='primary' />}
                        label={tenant.name}
                      />))}
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={() => getReportApi({data: reportSettings})}
              loading={isLoading}
              sx={{whiteSpace: 'nowrap'}}
            >
              Generate Report
            </HoopsButton>
          </Box>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
