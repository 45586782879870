import {AppAccess} from '../componentsHoops/AccessControl/AppAccess'; // Need the long form here because of dependency ordering
import {permissionCheck} from '../componentsHoops';

export const featureFlags = {
  stripe_integration_feature: {
    local: true,
    staging: true,
    production: true,
  },
  promostandards_sanmar: {
    local: true,
    staging: true,
    production: true,
  },
  promostandards_alphabroder: {
    staging: true,
    production: true,
    local: true,
  },
  promostandards_ssactivewear: {
    local: true,
    staging: false,
    production: false,
  },
  customer_rebuild: {
    local: false,
    staging: false,
    production: false,
  },
  kanban: {
    local: true,
    staging: true,
    production: true,
  },
  sticky_table: {
    local: false,
    staging: false,
    production: false,
  },
  empty_animation: {
    local: true,
    staging: true,
    production: false,
  },
  automations: {
    local: true,
    staging: true,
    production: true,
  },
  sms_notifications: {
    local: true,
    staging: true,
    production: true,
  },
  markups: {
    local: true,
    staging: true,
    production: true,
  },
  sage: {
    local: true,
    staging: true,
    production: true,
  },
  newQuoteBuilder: {
    local: true,
    staging: true,
    production: true,
    appAccess: AppAccess.NewQuoteBuilder,
  },
  disallowOldQuoting: {
    local: true,
    staging: true,
    production: true,
    appAccess: AppAccess.DisallowOldQuoting,
  },
};

export const featureFlagEnabled = (featureFlag) => {
  if (featureFlag?.appAccess && !permissionCheck({allowedAppAccess: [featureFlag.appAccess]})) {
    return false;
  }

  // the feature flag can be enabled for local, staging and production environments
  if (window.location.host === 'localhost:3000' && featureFlag?.local) {
    return true;
  }
  if (window.location.host === 'staging.hoopscrm.com' && featureFlag?.staging) {
    return true;
  }
  if (window.location.host === 'app.hoopscrm.com' && featureFlag?.production) {
    return true;
  }

  return false;
};
