import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router';
import {featureFlagEnabled} from '../../utils/featureFlag';

export const PermissionBasedRoute = ({
  children,
  allowedRoles = [],
  featureFlag,
  ...props
}) => {
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);
  const access = allowedRoles.indexOf(user.role) > -1;

  if (!user.role) {
    // wait for user to be downloaded and set
    return null;
  }

  if (featureFlag && !featureFlagEnabled(featureFlag)) {
    return <Redirect to={'/jobs'} />;
  }

  if (!access) {
    return <Redirect to='/accessdenied'/>;
  }

  return children && (
    <Route {...props}>{children}</Route>
  );
};
