import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.fields', (theme) => ({
  '&.columns, &.left-label': {
    display: 'grid',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
    '&.no-column-gap': {columnGap: 0,},
    '&.no-row-gap': {rowGap: 0,},
  },
  '&.columns': {
    padding: 0,
    rowGap: theme.spacing(2),
    '.basic-field': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  '&.fill-width': {width: '100%'},
  '&.medium .text-caption': {fontSize: '.875rem',},
  '&.left-label': {
    gridTemplateColumns: 'max-content auto',
    alignItems: 'baseline',
    '&>*:nth-child(even)': {overflow: 'hidden',},
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  '&.top-label': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '* + .text-caption': {marginTop: theme.spacing(1)},
  },
}));

/**
 * The idea here is that the children are a list of label - value pairs. They will be arranged
 * according to the layout types
 *
 * leftLabel: label on the left followed by the field on the right. All the labels will have the same width.
 *
 * topLabel: label above the field, there will be spacing between the fields.
 *
 * columns: Label and fields arranged in a grid with a fixed number of columns, label and field should be grouped in a <Field> element
 */
export const Fields = forwardRef(
function Fields({className, columns, fillWidth, leftLabel, medium, noGap, noColumnGap, noRowGap, topLabel, children, ...props}, ref) {
  noColumnGap = noColumnGap ?? noGap;
  noRowGap = noRowGap ?? noGap;

  return (
    <div
      ref={ref}
      className={classNames([
        className,
        'fields',
        columns && 'columns',
        fillWidth && 'fill-width',
        leftLabel && 'left-label',
        medium && 'medium',
        noColumnGap && 'no-column-gap',
        noRowGap && 'no-row-gap',
        topLabel && 'top-label',
      ])}
      {...props}
      style={{...props.style, ...(columns ? {gridTemplateColumns: typeof columns === 'string' ? columns : `repeat(${columns}, 1fr)`} : {})}}
    >
      {children}
    </div>
  );
});

Fields.propTypes = {
  className: HoopsPropTypes.className,
  columns: HoopsPropTypes.stringOrNumber,
  fillWidth: HoopsPropTypes.bool,
  leftLabel: HoopsPropTypes.bool,
  medium: HoopsPropTypes.bool,
  noGap: HoopsPropTypes.bool,
  noColumnGap: HoopsPropTypes.bool,
  noRowGap: HoopsPropTypes.bool,
  topLabel: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};

export function Field({className, gridColumn, children}) {
  const props = gridColumn ? {style: {gridColumn}} : {};

  return (
    <div className={classNames([className, 'basic-field'])} {...props}>
      {children}
    </div>
  );
}

Field.propTypes = {
  className: HoopsPropTypes.className,
  gridColumn: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
