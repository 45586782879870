import React, {useState} from 'react';
import {HoopsPropTypes} from '../utils';

export function MultiRowDragDrop({className, onDragEnd, children, draggable}) {
  const [temporaryItems, setTemporaryItems] = useState();
  const [draggedItem, setDragedItem] = useState();

  const renderedItems = temporaryItems || children;

  return (
    <>
      {renderedItems.map((item) => (
        <div
          key={item.key}
          style={{...(item === draggedItem ? {opacity: '0.4'} : {})}}
          draggable={draggable}
          className={className}
          onDragStart={() => {
            setDragedItem(item);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            if (!draggedItem || draggedItem === item) {
              return;
            }
            const currentIndex = renderedItems.indexOf(draggedItem);
            const targetIndex = renderedItems.indexOf(item);

            if (currentIndex !== -1 && targetIndex !== -1) {
              const newItems = [...renderedItems];
              newItems.splice(currentIndex, 1);
              newItems.splice(targetIndex, 0, draggedItem);
              setTemporaryItems(newItems);
            }
          }}
          onDragEnd={() => {
            onDragEnd(draggedItem, renderedItems);
            setDragedItem(undefined);
            setTemporaryItems(undefined);
          }}
        >
          {item}
        </div>
      ))}
    </>
  );
}

MultiRowDragDrop.propTypes = {
  className: HoopsPropTypes.className,
  onDragEnd: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
  draggable: HoopsPropTypes.bool,
};
