// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Button, Grid, Link, MuiThemeProvider} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {openCustomerModal as _openCustomerModal} from '../../actions/modals';
import green from '../../ui/theme/green';
import EntityListHero from '../EntityList/EntityListHero';
import CustomerTable from './CustomerManyTable';

class CustomersTab extends React.Component {
  state = {isCreateCustomerOpen: false};

  openCreateCustomer = () => {
    const {openCustomerModal} = this.props;

    openCustomerModal();
  };

  handleCreateCustomerClose = () => {
    this.setState({isCreateCustomerOpen: false});
  };

  componentDidMount() {
    document.title = 'Customers | Hoops';
  }

  render() {
    const {openCustomerModal} = this.props;
    return (
      <Grid container>
        <EntityListHero
          title='Customers'
          subtitle='Import, manage &amp; access all your customer information at any time.'
          helpArticleUrl='http://help.hoopscrm.com/en/articles/4632961-adding-companies-customers'
          videoId='oJjG9FUEMUo'
        >

          <Link
            component={RouterLink}
            to='/customers/import'
            underline='none'
          >
            <Button variant='outlined' color='primary'>Import</Button>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <MuiThemeProvider theme={green}>
            <Button data-intercom-target={'customers-table-add-customer'}
              onClick={openCustomerModal} color={'primary'} variant={'contained'}>Add Customer</Button>
          </MuiThemeProvider>
        </EntityListHero>
        <Grid item xs={12}>

            <CustomerTable openCreateCustomer={this.openCreateCustomer} />

        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {openCustomerModal: () => dispatch(_openCustomerModal())};
}

CustomersTab = connect(null, mapDispatchToProps)(CustomersTab);

export default CustomersTab;
