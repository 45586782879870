import {useCallback, useEffect, useRef} from 'react';

export function GlobalDragDropProvider() {
  const inWindowRef = useRef(false);

  const handleDragOver = useCallback((e) => {
    if (inWindowRef.current) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'none';
    }
  }, []);

  const handleDragEnter = useCallback((e) => {
    if (!inWindowRef.current) {
      let numFiles = 0, numImages = 0;
      for (let item of e.dataTransfer.items) {
        if (item.kind === 'file') {
          numFiles += 1;
          if (item.type.startsWith('image/')) {
            numImages += 1;
          }
        }
      }
      if (numFiles > 0) {
        inWindowRef.current = true;
        document.body.classList.add(
          'dragging-files',
          numFiles === 1 ? 'dragging-single-file' : 'dragging-multi-files',
          numImages === 1 ? 'dragging-single-image' : 'dragging-multi-images',
        );
        e.dataTransfer.dropEffect = 'none';
        e.preventDefault();
      }
    }
  }, []);

  const endDragDrop = useCallback(() => {
    if (inWindowRef.current) {
      inWindowRef.current = false;
      document.body.classList.remove(
        'dragging-files',
        'dragging-single-file',
        'dragging-multi-files',
        'dragging-single-image',
        'dragging-multi-images'
      );
    }
  }, []);

  const handleDragLeave = useCallback((e) => {
    if (inWindowRef.current) {
      if (e.clientX === 0 && e.clientY === 0) {
        endDragDrop();
      }
      e.preventDefault();
    }
  }, [endDragDrop]);

  const handleDrop = useCallback((e) => {
    if (inWindowRef.current) {
      endDragDrop();
      e.preventDefault();
    }
  }, [endDragDrop]);

  useEffect(() => {
    document.addEventListener('dragenter', handleDragEnter, true);
    document.addEventListener('dragleave', handleDragLeave, true);
    document.addEventListener('dragover', handleDragOver, true);
    document.addEventListener('drop', handleDrop, true);

    return () => {
      document.removeEventListener('dragenter', handleDragEnter, true);
      document.removeEventListener('dragleave', handleDragLeave, true);
      document.removeEventListener('dragover', handleDragOver, true);
      document.removeEventListener('drop', handleDrop, true);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  return null;
}
