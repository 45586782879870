import {FormControl, FormHelperText, makeStyles, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EditorField from '../../../companies/editor';
import store from '../../../../store';

import {useMutation} from '@apollo/client';
import {GQL_SEND_CATALOG_UPDATE} from '../../../../queries/catalogImport';
import EmailTemplateService from '../../../../servicesOld/EmailTemplateService';
import {snackError, snackSuccess} from '../../../../actions/action-types';
import {HoopsTextField} from '../../../HoopsTextField';

const useStyles = makeStyles((theme) => ({greyText: {color: theme.colors.grey.main}}));

export const SendImportEmailForm = ({
  sendFunction = () => null,
  setSendOpen = () => null,
  setIsValid = () => null,
  setIsLoading = () => null,
  companies = [],
  catalog = 'this'
}) => {
  const company = store.getState()?.companyReducer.company;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);

  const [errors, setErrors] = useState({});
  const [emailOptions, setEmailOptions] = useState({
    to: '',
    cc: '',
    bcc: '',
    replyTo: user.username,
    subject: '',
    body: '',
    company: company
  });

const [handleSendImportEmail] = useMutation(GQL_SEND_CATALOG_UPDATE, {
  fetchPolicy: 'no-cache',
  onError: (error) => {
    if (error) {
      setIsLoading(false);
      dispatch(snackError('Error Sending Email. Please carefully check your email template and short codes.'));
    }
  },
  onCompleted: (result) => {
    if (result) {
      setIsLoading(false);
      setSendOpen(false);
      dispatch(snackSuccess('Catalog update email has been sent'));
    }
  },
});

  useEffect(() => {
    const handleSend = () => {
      setIsLoading(true);
      handleSendImportEmail({variables: {_id: catalog._id, emailOptions: EmailTemplateService.cleanEmailContent(emailOptions)}});
      setSendOpen(false);
    };
    sendFunction.current = handleSend;
  }, [catalog._id, handleSendImportEmail, sendFunction, emailOptions, setIsLoading, setSendOpen]);

  // If there are shared companies set form to valid
  useEffect(() => {
    if (companies.length > 0) {
      setIsValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const {name, value, type} = e.target;
    const validEmail = type === 'email' && EmailTemplateService.validateEmail(value);
    setEmailOptions((prev) => ({...prev, [name]: value}));

    // Clear validation errors
    if ((value !== '' || value)) {
      if (type === 'email' && !validEmail) { return; }
      setErrors((prev) => {
        delete prev[name];
        return {...prev};
      });
      return;
    }
  };

  const handleValidate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;
    const required = e.target.required;
    const validEmail = type === 'email' && EmailTemplateService.validateEmail(value);
    if ((value === '' || !value) && required) {
      setErrors((prev) => ({...prev, [name]: 'This field is required'}));
      return;
    }
    if (type === 'email' && !validEmail && (value !== '' || value)) {
      setErrors((prev) => ({...prev, [name]: 'This email address is invalid'}));
      return;
    }
    setErrors((prev) => {
      delete prev[name];
      return {...prev};
    });
  };

  const handleBodyChange = (value) => {
    setEmailOptions((prev) => ({...prev, body: value}));
    if (value === '' || !value || value === '<p></p>\n') {
      setErrors((prev) => ({...prev, body: true}));
    } else {
      setErrors((prev) => {
        delete prev.body;
        return {...prev};
      });
    }
  };

  return (
    <>
      <Grid container spacing={1}>
      <Grid item xs={6}>
          <Typography variant='body2'>Send an email to all companies that the catalog has been shared with, the email will not send to companies with an inactive stripe status.</Typography>
        </Grid>
        <Grid item xs={12}>
              <HoopsTextField
                name={'subject'}
                label={'Subject:'}
                defaultValue={emailOptions.subject}
                onChange={handleChange}
                onBlur={handleValidate}
                required
                error={errors.subject}
                helperText={errors.subject && errors.subject}
              />
            </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' className={classes.greyText}>Body</Typography>
          <FormControl fullWidth error={errors.body} >
            <EditorField
              input={{
                value: emailOptions.body,
                onChange: handleBodyChange
              }}
              meta={true}
              error={errors.body}
            />
            <FormHelperText>{errors.body && 'Text is required in the email template'}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
