import React, {useEffect, useState} from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmDeleteDialog from '../confirmDeleteDialog';
import {
  deleteQuote,
  getQuote,
  convertToJob,
  duplicateQuote
} from '../../actions/quote';
import PreviewQuoteDialog from './PreviewQuote/PreviewQuoteDialog';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {
  openSendQuoteModal,
  setInvoiceModalOnClose
} from '../../actions/modals';
import PaymentIcon from '@material-ui/icons/Payment';
import {draftInvoiceFromQuote, getInvoice} from '../../actions/invoice';
import {editNewTask} from '../../actions/tasks';
import {get} from 'lodash';
import moment from 'moment';
import {permissionCheck, SubscriptionTypes, XeroPaymentInfoDialog} from '../../componentsHoops';
import {useMutation} from '@apollo/client';
import {GQL_QUOTE_SET_ARCHIVED} from '../../queries/quotes';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import {CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import copy from 'copy-to-clipboard';
import {snackSuccess} from '../../actions/action-types';

export function QuoteMenuCell({rowData, quoteTableRef, children}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [xeroPaymentInfoDialogOpen, setXeroPaymentInfoDialogOpen] = useState(false);
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const quoteSelector = (state) => state.quoteReducer;
  const {quote} = useSelector(quoteSelector);
  const [accountConnected, setAccountingConnected] = useState(false);
  const history = useHistory();

  const Spinner = styled(CircularProgress)(() => ({marginLeft: 5, marginRight: 35,}));

  const [quoteSetArchived, {loading}] = useMutation(GQL_QUOTE_SET_ARCHIVED);

  useEffect(() => {
    let refreshTokenExpiry = 0;

    if (get(company, 'accountingPlatform.refreshTokenExpiry')) {
      refreshTokenExpiry = get(company, 'accountingPlatform.refreshTokenExpiry');
    }

    if (moment(refreshTokenExpiry, 'x').isAfter()) {
      setAccountingConnected(true);
    }
  }, [company]);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (!quoteTableRef?.current) {
      dispatch(getQuote(rowData?._id));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArchived = async (quoteArg) => {
    await quoteSetArchived({variables: {_id: quoteArg._id, archived: !quoteArg.archived}});
    dispatch(hoopsQuerySetRefetch('quote', true));
  };

  const copyCustomerLink = (quoteArg) => {
    if(quoteArg.documentType === 'quoteV2'){
      copy(`${window.location.origin}/public/sales/${quoteArg._id.toString()}/view`);
    }
    if(quoteArg.documentType === 'classic' || !quoteArg.documentType){
      copy(`${window.location.origin}/public/quotes/${quoteArg._id.toString()}/view`);
    }
    dispatch(snackSuccess('Link copied.'));
    setAnchorEl(null);
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
    setAnchorEl(null);
  };

  const handleInvoiceModalClose = () => {
    // update quotes table or quote
    if (quoteTableRef) {
      quoteTableRef.current && quoteTableRef.current.onQueryChange();
    } else {
      dispatch(getQuote(rowData?._id));
    }
  };

  const handleGetInvoice = () => {
    const invoiceId = get(rowData, 'invoice._id', null);
    dispatch(setInvoiceModalOnClose(handleInvoiceModalClose));
    if (!invoiceId) {
      dispatch(draftInvoiceFromQuote(rowData?._id));
    } else if (company.accountingPlatform?.platform === 'XERO' && rowData?.invoice?.balance < rowData?.invoice?.totalAmount) {
      setXeroPaymentInfoDialogOpen(true);
    } else {
      dispatch(getInvoice(invoiceId));
    }
    handleClose();
  };

  const openPreviewModal = async () => {
    dispatch(getQuote(rowData?._id));
    setPreviewDialogOpen(true);
    setAnchorEl(null);
  };

  const openSendEmailDialog = async (renderSiblingsBoolean) => {
    dispatch(getQuote(rowData?._id));
    dispatch(openSendQuoteModal({renderSiblings: renderSiblingsBoolean}));
    setAnchorEl(null);
  };

  const closePreviewModal = () => {
    setPreviewDialogOpen(false);
  };

  const handleDeleteQuote = async () => {
    await dispatch(deleteQuote(rowData?._id));
    setConfirmDelete(false);
  };

  const handleDuplicateQuote = (_id) => {
    setAnchorEl(null);
    dispatch(duplicateQuote(_id));
    history.push('/quotes/new');
  };

  const handleConvertToJob = () => {
    dispatch(convertToJob(rowData?._id));
    history.push('/jobs/new');
  };

  const handleAddTask = () => {
    dispatch(editNewTask({
      quoteIds: [rowData?._id],
      quotes: [rowData]
    }));
    handleClose();
  };

  const quoteLabel = (rowData?.status === 'DRAFT' ? 'DRAFT Quote' : `Quote Number ${rowData?.number}`);
  const pdfLink = `${process.env.REACT_APP_BASE_URL}/quotes/${rowData?._id}/pdf`;
  const invoicePdfLink = `${process.env.REACT_APP_BASE_URL}/rest/accounting/invoices/${rowData?.invoice?._id}/pdf`;

  return (
    <div key={rowData?._id}>
      {children ?
        <div onClick={handleClick}> {children} </div> :
        <IconButton
          onClick={handleClick}
          variant={'outlined'}
          size={'small'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>}
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!children && <RouterLink to={`/quotes/${rowData?._id}/view`} style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
          <MenuItem>
            <ListItemIcon>
              <Icon>visibility</Icon>
            </ListItemIcon>
            View
          </MenuItem>
        </RouterLink>}
        <RouterLink to={`/quotes/${rowData?.number}/edit`} style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
          <MenuItem>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
        </RouterLink>
        <MenuItem onClick={() => copyCustomerLink(rowData)}>
          <ListItemIcon>
            <Icon>link</Icon>
          </ListItemIcon>
          Copy Quote Link
        </MenuItem>
        <MenuItem
          component={Link}
          href={pdfLink}
          color='inherit'
          underline='none'
          onClick={handleClose}
          target='_blank'
        >
          <ListItemIcon>
            <Icon>description</Icon>
          </ListItemIcon>
          Get PDF
        </MenuItem>
        <MenuItem onClick={openConfirmDelete}>
          <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon>
          Delete
        </MenuItem>
        <MenuItem onClick={() => openSendEmailDialog(false)}>
          <ListItemIcon>
            <Icon>send</Icon>
          </ListItemIcon>
          Send
        </MenuItem>
        {rowData?.isSibling &&
          <MenuItem onClick={() => openSendEmailDialog(true)}>
            <ListItemIcon>
              <Icon>send</Icon>
            </ListItemIcon>
            Send Group
          </MenuItem>
        }
        <MenuItem onClick={() => handleDuplicateQuote(rowData?._id)} disabled={rowData?.isSibling}>
          <ListItemIcon>
            <Icon>restore_page</Icon>
          </ListItemIcon>
          Duplicate
        </MenuItem>
        <MenuItem onClick={openPreviewModal}>
          <ListItemIcon>
            <Icon>find_in_page</Icon>
          </ListItemIcon>
          Preview
        </MenuItem>
        {rowData?.job ?
          <RouterLink to={`/jobs/${rowData?.job._id}/details`}
            style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
            <MenuItem>
              <ListItemIcon>
                <Icon>link</Icon>
              </ListItemIcon>
              View Linked Job
            </MenuItem>
          </RouterLink> :
          <MenuItem onClick={handleConvertToJob}>
            <ListItemIcon>
              <Icon>file_copy</Icon>
            </ListItemIcon>
            Create Job
          </MenuItem>}
        <MenuItem data-intercom-target={'job-add-task'} onClick={handleAddTask}>
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          Add Task
        </MenuItem>
        {rowData?.archived
          ? <MenuItem data-intercom-target={'quote-unarchived'} onClick={() => handleArchived(rowData)}>
            {loading
              ? <Spinner size={16} />
              : <ListItemIcon>
                <Icon>unarchived</Icon>
              </ListItemIcon>
            }
            UnArchive
          </MenuItem>
          : <MenuItem data-intercom-target={'quote-archived'} onClick={() => handleArchived(rowData)} disabled={loading}>
            {loading
              ? <Spinner size={16} />
              : <ListItemIcon>
                <Icon>unarchived</Icon>
              </ListItemIcon>
            }
            Archive
          </MenuItem>
        }
        {accountConnected && !get(quote, 'invoice._id', null)
          && permissionCheck({disallowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) &&
          <MenuItem onClick={handleGetInvoice}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            Create Invoice
          </MenuItem>
        }
        {accountConnected && get(quote, 'invoice._id', null)
          && permissionCheck({disallowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) &&
          <>
            <MenuItem onClick={handleGetInvoice}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              Edit Invoice
            </MenuItem>
            <MenuItem
              component={Link}
              href={invoicePdfLink}
              color='inherit'
              underline='none'
              onClick={handleClose}
              target='_blank'
            >
              <ListItemIcon>
                <Icon>description</Icon>
              </ListItemIcon>
              Get Invoice PDF
            </MenuItem>
          </>
        }
      </Menu>
      <ConfirmDeleteDialog name={quoteLabel} isOpen={confirmDelete} ok={handleDeleteQuote} />
      {previewDialogOpen && <PreviewQuoteDialog quoteId={rowData?._id} open={previewDialogOpen} handleClose={closePreviewModal} />}
      {xeroPaymentInfoDialogOpen && <XeroPaymentInfoDialog open={xeroPaymentInfoDialogOpen} onClose={() => setXeroPaymentInfoDialogOpen(false)} />}
    </div>
  );
}
