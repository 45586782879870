import React, {useCallback, useState} from 'react';
import {Button, PasswordInput, TextInput, ToolTip} from '../../../componentsLib/Basic';
import {Column, Field, Fields, Row, usePageContext} from '../../../componentsLib/Layout';
import {Placement, ToolboxSection} from '../../../componentsLib/Popovers';
import {indexOfEventTarget, valueFromEvent} from '../../../utils';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {useSaveVendor} from '../../../hooks/api';

const EMPTY_CONTACT = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};
const EMPTY_ADDRESS = {
  address1: '',
  city: '',
  state: '',
  postcode: '',
  country: '',
};
const EMPTY_VENDOR = {
  name: '',
  email: '',
  contacts: [EMPTY_CONTACT],
  addresses: [EMPTY_ADDRESS],
};

export function AddVendorToolbox() {
  const {salesDoc, selection, setSelection} = usePageContext();
  const {save: saveVendorApi, isSaving} = useSaveVendor();
  const [vendor, setVendor] = useState(EMPTY_VENDOR);

  const handleCancel = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: undefined}));
  }, [setSelection]);

  const item = salesDoc.getVariantItems(selection.variantId)[0] ?? salesDoc.getItem(selection.itemId);
  const handleSave = useCallback(async () => {
    const sendVendor = {
      ...vendor,
      addresses: vendor.addresses.filter(({address1, city, state, postcode, country}) => address1 || city || state || postcode || country),
    };
    const savedVendor = await saveVendorApi({vendor: sendVendor});
    console.log(savedVendor);
    item.setVendor(savedVendor.vendor);
    setSelection((prev) => ({...prev, subSection: undefined}));
  }, [item, saveVendorApi, setSelection, vendor]);

  const handleChangeFieldValue = useCallback((e) => {
    setVendor((prev) => ({...prev, [e.target.name]: valueFromEvent(e)}));
  }, []);

  const handleChangeOrdersSiteFieldValue = useCallback((e) => {
    setVendor((prev) => ({...prev, ordersSite: {...prev.ordersSite, [e.target.name]: valueFromEvent(e)}}));
  }, []);

  const handleChangeContactFieldValue = useCallback((e) => {
    const index = indexOfEventTarget(e, {selector: '.column'});
    setVendor((prev) => ({
      ...prev,
      contacts: prev.contacts.toSpliced(index, 1, {...prev.contacts[index], [e.target.name]: valueFromEvent(e)})
    }));
  }, []);

  const handleAddContact = useCallback(() => {
    setVendor((prev) => ({...prev, contacts: [...prev.contacts, EMPTY_CONTACT]}));
  }, []);

  const handleCopyContact = useCallback((e) => {
    const index = indexOfEventTarget(e, {selector: '.column'});
    setVendor((prev) => ({
      ...prev,
      contacts: prev.contacts.toSpliced(index + 1, 0, {...prev.contacts[index]}),
    }));
  }, []);

  const handleDeleteContact = useCallback((e) => {
    const index = indexOfEventTarget(e, {selector: '.column'});
    setVendor((prev) => ({...prev, contacts: prev.contacts.toSpliced(index, 1)}));
  }, []);

  const handleChangeAddressFieldValue = useCallback((e) => {
    const index = indexOfEventTarget(e, {selector: '.column'});
    setVendor((prev) => ({
      ...prev,
      addresses: prev.addresses.toSpliced(index, 1, {...prev.addresses[index], [e.target.name]: valueFromEvent(e)})
    }));
  }, []);

  const handleAddAddress = useCallback(() => {
    setVendor((prev) => ({...prev, addresses: [...prev.addresses, EMPTY_ADDRESS]}));
  }, []);

  const handleCopyAddress = useCallback((e) => {
    const index = indexOfEventTarget(e, {selector: '.column'});
    setVendor((prev) => ({
      ...prev,
      addresses: prev.addresses.toSpliced(index + 1, 0, {...prev.addresses[index]}),
    }));
  }, []);

  const handleDeleteAddress = useCallback((e) => {
    const index = indexOfEventTarget(e, {selector: '.column'});
    setVendor((prev) => ({...prev, addresses: prev.addresses.toSpliced(index, 1)}));
  }, []);

  let isValid = !!vendor.name
    && !!vendor.email
    && vendor.contacts.length > 0
    && vendor.contacts.every((contact) => !!contact.firstName && !!contact.email);

  return (
    <SalesDocToolbox className={'add-vendor-toolbox'} heading={'Add Vendor'} onBack={handleCancel} noSettings>
      <ToolboxSection heading={'Details'} small>
        <Fields topLabel>
          <TextInput label={'Vendor Name:'} value={vendor.name} name={'name'} required onChange={handleChangeFieldValue}/>
          <TextInput label={'Email:'} value={vendor.email} name={'email'} required onChange={handleChangeFieldValue}/>
        </Fields>
      </ToolboxSection>
      <ToolboxSection heading={'Contacts'} innerSep small>
        <Column>
          {vendor.contacts.map((contact, index) => (
            <Column key={index} className='field-group'>
              <Fields columns={'120fr 120fr 160fr 260fr'}>
                <TextInput label={'First Name:'} value={contact.firstName} required field name={'firstName'} onChange={handleChangeContactFieldValue}/>
                <TextInput label={'Last Name:'} value={contact.lastName} field name={'lastName'} onChange={handleChangeContactFieldValue}/>
                <TextInput label={'Phone:'} value={contact.phone} field name={'phone'} onChange={handleChangeContactFieldValue}/>
                <TextInput label={'Email:'} value={contact.email} required field name={'email'} onChange={handleChangeContactFieldValue}/>
              </Fields>
              <Row gap>
                <Button actionPrimary onClick={handleAddContact}>Add Another Contact</Button>
                <Button actionPrimary onClick={handleCopyContact}>Copy this Contact</Button>
                <Button actionPrimary onClick={handleDeleteContact}>Delete this Contact</Button>
              </Row>
            </Column>
          ))}
        </Column>
      </ToolboxSection>
      <ToolboxSection heading={'Addresses'} innerSep small>
        <Column>
          {vendor.addresses.map((address, index) => (
            <Column key={index} className='field-group'>
              <Fields columns={2} fillWidth>
                <Field gridColumn={'1/3'}>
                  <TextInput label={'Address:'} name={'address1'} value={address.address1} onChange={handleChangeAddressFieldValue}/>
                </Field>
                <TextInput label={'City:'} field name={'city'} value={address.city} onChange={handleChangeAddressFieldValue}/>
                <TextInput label={'State:'} field name={'state'} value={address.state} onChange={handleChangeAddressFieldValue}/>
                <TextInput label={'Postcode:'} field name={'postcode'} value={address.postcode} onChange={handleChangeAddressFieldValue}/>
                <TextInput label={'Country:'} field name={'country'} value={address.country} onChange={handleChangeAddressFieldValue}/>
              </Fields>
              <Row gap>
                <Button actionPrimary onClick={handleAddAddress}>Add Another Address</Button>
                <Button actionPrimary onClick={handleCopyAddress}>Copy this Address</Button>
                <Button actionPrimary onClick={handleDeleteAddress}>Delete this Address</Button>
              </Row>
            </Column>
          ))}
        </Column>
      </ToolboxSection>
      <ToolboxSection heading={'Website'} innerSep small>
        <Fields columns={2} fillWidth>
          <Field gridColumn={'1/3'}>
            <TextInput label={'Orders Website:'} name={'website'} value={vendor.website} onChange={handleChangeFieldValue}/>
          </Field>
          <TextInput label={'Username:'} field name={'username'} autoComplete={'new-password'} value={vendor.username} onChange={handleChangeFieldValue}/>
          <PasswordInput
            label={'Password:'}
            field
            name={'password'}
            value={vendor.password}
            autoComplete={'new-password'}
            allowShowPassword
            onChange={handleChangeOrdersSiteFieldValue}
            trim={false}
          />
        </Fields>
      </ToolboxSection>
      <ToolboxSection stickyFooter>
        <Row justifySpaceBetween>
          <Button navNegative text={'Cancel'} onClick={handleCancel}/>
          <ToolTip
            placement={Placement.Left}
            tip={!isValid && 'Please ensure all required fields are filled to save.'}
          >
            <Button navPositive text={'Save'} disabled={!isValid} onClick={handleSave} loading={isSaving}/>
          </ToolTip>
        </Row>
      </ToolboxSection>
    </SalesDocToolbox>
  );
}
