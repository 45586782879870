import {Children} from 'react';
import {isFragment} from 'react-is';

export function flattenChildren(children) {
  return Children.toArray(children).reduce((acc, child) => {
    if (isFragment(child)) {
      acc.push(...flattenChildren(child.props.children));
    } else {
      acc.push(child);
    }
    return acc;
  }, []);
}
