import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {openCustomerModal} from '../../actions/modals';
import {GQL_GET_CONTACTS} from '../../queries/contacts';
import {CustomerService} from '../../servicesOld/CustomerService';
import {client} from '../../servicesOld/GraphQLService';
import HoopsCustomAutocomplete from '../shared/formHelpers/HoopsCustomAutocomplete';
import {useDebounce} from '../../hooks';
import {initials} from '../../utils';

const useStyles = makeStyles((theme) => ({
  orange: {backgroundColor: theme.colors.orange},
  customerDetails: {
    fontSize: '10px',
    lineHeight: '20px',
  },
  contactName: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  whiteSpaceNoWrap: {whiteSpace: 'nowrap'}
}));

const RenderOption = (option, {searchString, classes}) => {
  function highlightedWord(word) {
    const matches = match(word, searchString);
    const parts = parse(word, matches);
    return (
      <>
        {parts.map((part, index) =>
          part.highlight ?
            <span key={String(index)} style={{fontWeight: 500}}>
              {part.text}
            </span>
            : <strong key={String(index)} style={{fontWeight: 300}}>
              {part.text}
            </strong>
        )}
      </>
    );
  }

  return (
    <Grid container>
      <Grid item>
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          <Grid item>
            <Avatar className={classes.orange}>
              {initials(option.customer.name)}
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <Typography className={classNames(classes.contactName, classes.whiteSpaceNoWrap)}>
                  {highlightedWord(option.fullName)}
                </Typography>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography className={classNames(classes.customerDetails, classes.whiteSpaceNoWrap)}>
                    {highlightedWord(option.customer.name)}
                    {option.email && ` | ${option.email}`}
                    {option.phone && ` | ${option.phone}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

function ContactAutocomplete({textFieldProps, input: {onChange}, input, ...params}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [searchString, setSearchString] = useState('');

  const handleCustomerClose = useCallback((customer) => {
    if (customer && customer._id) {
      const contact = customer.contacts[0];
      onChange(customer.contacts[0]);
      setSearchString(contact.fullName);

      /**
       * We are getting the contact from server with the customer attached
       * this will give all of the details that we didn't already have
       * There is room to improve this
       */
      CustomerService.getContact(contact._id)
        .then((contactResult) => {
          onChange(contactResult);
          setSearchString(contactResult.fullName);
        });
    }
  }, [onChange]);

  const handleButtonClick = useCallback(() => {
    dispatch(openCustomerModal({callback: handleCustomerClose}));
  }, [dispatch, handleCustomerClose]);

  const getContacts = useDebounce(async (value) => {
    // This is a hack to return lots of results if there is a
    const limit = value.length > 2 ? 50 : 5;

    const {data} = await client.query({
      query: GQL_GET_CONTACTS,
      variables: {
        limit,
        filter: {
          q: value,
          _operators: {
            customerId: { // ensure we are only fetching Contacts for Customers and not
              exists: true,
              ne: null
            }
          }
        }
      },
      fetchPolicy: 'no-cache'
    });
    setCustomers(data.contactMany);
  }, []);

  useEffect(() => {
    getContacts(searchString);
  }, [getContacts, searchString]);

  useEffect(() => {
    if (input && input.value && input.value !== '') {
      setSearchString(input.value.fullName ? input.value.fullName : '');
    }
  }, [input]);

  return (
    <>
      <HoopsCustomAutocomplete
        input={input}
        options={customers}
        textFieldProps={textFieldProps}
        RenderOption={(option) => RenderOption(option, {searchString, classes})}
        setSearchString={setSearchString}
        onSelect={(val) => {
          onChange(val);
          setSearchString(val.fullName);
        }}
        buttonProps={{
          label: 'Add New Customer',
          onClick: handleButtonClick
        }}
        inputValue={searchString}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.fullName}
        {...params}
      />
    </>
  );
}

export default ContactAutocomplete;
