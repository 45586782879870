import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Row} from '../Layout';
import {RectangularSkeleton} from '../Skeletons';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import classNames from 'classnames';
import {
  ChevronLeft as ScrollLeftIcon,
  ChevronRight as ScrollRightIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import {ImageThumbnail} from './ImageThumbnail';
import {ImagePreview} from './ImagePreview';
import {BodyText} from '../Text';

registerGlobalStyle('.image-gallery', (theme) => ({
  width: '100%',
  '.image-container': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '.image-preview': {
      'img, canvas': {
        border: `1px solid ${theme.colors.border.transparent}`,
        borderRadius: theme.shape.borderRadius,
        transition: theme.transitions.out.border,
      },
    },
    '.previous-button, .next-button': {
      fontSize: 'xx-large',
      position: 'absolute',
      height: '100%',
      zIndex: theme.zIndex.toolbox,
    },
    '.previous-button': {left: 0,},
    '.next-button': {right: 0,},
  },
  '.thumnbails-row': {
    justifyContent: 'start',
    marginTop: theme.spacing(2),
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    '.image-thumbnail': {
      'img, canvas': {
        width: 95,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.colors.border.transparent}`,
        transition: theme.transitions.out.border,
        '&:hover': {
          borderRadius: theme.shape.borderRadius,
          border: `1px solid ${theme.colors.border.dark}`,
          transition: theme.transitions.in.border,
        }
      }
    },
    '.image-thumbnail.active': {
      'img, canvas': {
        border: `1px solid ${theme.colors.border.dark}`,
        borderRadius: theme.shape.borderRadius,
      }
    }
  },
}));

registerGlobalStyle('.zoomed-gallery', (theme) => ({
  position: 'absolute',
  pointerEvents: 'none',
  opacity: 0,
  transition: theme.transitions.out.opacity,
  zIndex: theme.zIndex.imageZoom,
  '.image-container': {
    position: 'fixed',
    height: 'auto',
    maxWidth: '100vw',
    maxHeight: '100vh',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.background.white,
    'img, canvas': {
      objectFit: 'contain',
      height: 'auto',
      maxWidth: '80vw',
      maxHeight: '100vh',
    },
    '.next-button, .previous-button': {
      width: 75,
      height: '80vh'
    },
    '.close-icon': {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(2),
      borderRadius: 48,
      fontSize: 35
    },
    '.image-count': {
      position: 'absolute',
      top: 0,
      padding: theme.spacing(3),
      fontSize: 18,
    },
  },
  '&.zoom-enabled': {
    opacity: 1,
    transition: theme.transitions.in.opacity,
    '.image-container, .next-button, .previous-button': {pointerEvents: 'all'}
  }
}));

export const ImageGallery = ({className, images, zoom, thumbs, hoverZoom}) => {
  const [image, setImage] = useState(images[0] ?? null);
  const [zoomedImage, setZoomedImage] = useState(images[0] ?? null);
  const [isZoomed, setIsZoomed] = useState(false);
  const galleryRef = useRef();

  const handleZoomToggle = useCallback(() => {
    if (!isZoomed) {
      galleryRef.current.focus();
      setZoomedImage(image);
    }
    if (zoom) {
      setIsZoomed(!isZoomed);
    }
  }, [zoom, image, isZoomed, setIsZoomed, setZoomedImage]);

  useEffect(() => {
    if (!image) {
      setImage(images[0]);
    }
    if (!images.includes(image)) {
      setImage(images[0]);
    }
  }, [images, image]);

  const nextImage = useCallback((e) => {
    e.stopPropagation();
    if (!isZoomed) {
      const imageIndex = images.findIndex((index) => index === image);
      setImage(images[images.length - 1 === imageIndex ? 0 : imageIndex + 1]);
    }
    if (isZoomed) {
      const imageIndex = images.findIndex((index) => index === zoomedImage);
      setZoomedImage(images[images.length - 1 === imageIndex ? 0 : imageIndex + 1]);
    }
  }, [image, images, isZoomed, zoomedImage, setZoomedImage]);

  const previousImage = useCallback((e) => {
    e.stopPropagation();
    if (!isZoomed) {
      const imageIndex = images.findIndex((index) => index === image);
      setImage(images[imageIndex === 0 ? images.length - 1 : imageIndex - 1]);
    }
    if (isZoomed) {
      const imageIndex = images.findIndex((index) => index === zoomedImage);
      setZoomedImage(images[imageIndex === 0 ? images.length - 1 : imageIndex - 1]);
    }
  }, [image, images, isZoomed, zoomedImage, setZoomedImage]);

  const handleEscape = useCallback((event) => {
    if (event.key === 'Escape' && isZoomed) {
      handleZoomToggle();
    }
  }, [isZoomed, handleZoomToggle]);

  return (
    <div className={classNames([className, 'image-gallery'])} ref={galleryRef} tabIndex={0} onKeyDown={handleEscape}>
      {images?.length > 0 &&
        <>
          <div className={'image-container'}>
            {images?.length > 1 &&
              <ScrollLeftIcon className={'previous-button'} onClick={previousImage} />
            }
            <ImagePreview
              className={classNames([zoom && 'zoom-cursor'])}
              key={image}
              hoverZoom={hoverZoom}
              imageUrl={image}
              onClick={handleZoomToggle}
              setByContainerWidth
            />

            {images?.length > 1 &&
              <ScrollRightIcon className={'next-button'} onClick={nextImage} />
            }
          </div>
          {thumbs &&
            <Row wrap className={'thumnbails-row'}>
              {images.map((imageUrl) => (
                <ImageThumbnail
                  key={imageUrl}
                  width={95}
                  hoverZoom={hoverZoom}
                  imageUrl={imageUrl}
                  className={classNames([imageUrl === image && 'active'])}
                  onClick={() => setImage(imageUrl)}
                />
              ))}
            </Row>
          }
        </>
      }
      {images?.length === 0 &&
        <>
          <RectangularSkeleton rounded animation={false} width={544} height={544} />
          <Row className={'thumnbails-row'}>
            <RectangularSkeleton className={'thumbnail'} rounded animation={false} width={118} height={118} />
            <RectangularSkeleton className={'thumbnail'} rounded animation={false} width={118} height={118} />
            <RectangularSkeleton className={'thumbnail'} rounded animation={false} width={118} height={118} />
            <RectangularSkeleton className={'thumbnail'} rounded animation={false} width={118} height={118} />
          </Row>
        </>
      }
      <div className={classNames(['zoomed-gallery', isZoomed && 'zoom-enabled'])}>
        <div className={classNames(['image-container'])} onClick={handleZoomToggle}>
          <BodyText text={`${images.indexOf(zoomedImage) + 1} / ${images.length}`} className={'image-count'} />
          <CloseIcon className={'close-icon'}/>
          <ImagePreview
            className={classNames([zoom && 'zoom-cursor'])}
            imageUrl={zoomedImage}
            onClick={handleZoomToggle}
            setByContainerHeight
            pagination
          />
          {images?.length > 1 &&
            <>
              <ScrollLeftIcon className={'previous-button'} onClick={previousImage} />
              <ScrollRightIcon className={'next-button'} onClick={nextImage} />
            </>
          }
        </div>
      </div>
    </div>
  );
};

ImageGallery.propTypes = {
  className: HoopsPropTypes.className,
  images: HoopsPropTypes.arrayOfString,
  zoom: HoopsPropTypes.bool,
  thumbs: HoopsPropTypes.bool,
  hoverZoom: HoopsPropTypes.bool,
};
