// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useEffect, useState} from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreButton from '../../../shared/ExpandMoreButton';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core';
import ChipSelect from '../../ChipSelect';
import {ITEM_STATUSES, VARIANT_STATUSES} from '../../../../constants/status';
import {updateJobItem as _updateJobItem, updateJobItemVariant as _updateJobItemVariant} from '../../../../actions/jobs';
import {connect} from 'react-redux';
import * as classnames from 'classnames';
import {get} from 'lodash';
import {makeStyles} from '@material-ui/styles';
import ImageWithTooltip from '../../../shared/ImageWithTooltip';
import StatusButton from '../../../../pages/JobsPage/StatusPicker/StatusPicker';

const styles = (theme) => ({
  itemSummaryRow: {
    border: '0px',
    borderTop: `1px solid ${theme.colors.grey.light}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  noBorder: {border: '0px'},
  bottomBorder: {borderBottom: `1px solid ${theme.colors.grey.light}`,},
  popper: {opacity: 1},
  tooltip: {maxWidth: '450px'},
  chevronDown: {transform: 'scale(1, -1)'},
  thumbnail: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px'
  },
  subHeading: {
    color: `${theme.colors.grey.main} !important`,
    fontSize: '12px !important'
  }
});

function RenderVariations({Item, classes, variants, updateJobItemVariant}) {
  return variants.map((variant, index) => {
    const vendorName = get(variant, 'vendor.name', '-');
    const handleStatusChange = (newStatus) => {
      Item.jobItemVariant.variants[index].statusLabel = newStatus.name;
      updateJobItemVariant(Item.jobItemVariant._id, Item.jobItemVariant);
    };

    return (
      <TableRow key={index}>
        <TableCell className={classnames([classes.noBorder, 'noPrint'])} colSpan={2}/>
        <TableCell className={classes.noBorder}>{variant.productCode}</TableCell>
        <TableCell className={classes.noBorder}>{variant.productName}</TableCell>
        <TableCell className={classes.noBorder}>{vendorName}</TableCell>
        <TableCell className={classes.noBorder}>{variant.colour}</TableCell>
        <TableCell className={classes.noBorder}>{variant.size}</TableCell>
        <TableCell className={classes.noBorder}>{variant.quantity}</TableCell>
        <TableCell className={classes.noBorder}>
          <ChipSelect activeOption={variant.status}
                      disabled
                      data-intercom-target={'job-item-variations-status'}
                      options={VARIANT_STATUSES}
                      fallbackOption={VARIANT_STATUSES[0]}
                      onOptionChange={handleStatusChange}/>
        </TableCell>
      </TableRow>);
  });
}

function RenderDecorations({classes, decorations}) {
  return decorations.map((decoration, index) => (
    <TableRow key={index}>
      <TableCell className={classnames([classes.noBorder, 'noPrint'])}/>
      <TableCell className={classes.noBorder}/>
      <TableCell className={classes.noBorder} colSpan={2}>
        <Typography variant={'body2'}>{decoration.description ? decoration.description : decoration.name}</Typography>
      </TableCell>
      <TableCell className={classes.noBorder} colSpan={2}>
        <Typography variant={'body2'}>{get(decoration, 'vendor.name', '-')}</Typography>
      </TableCell>
      <TableCell className={classes.noBorder}>{get(decoration, 'colour', '')}</TableCell>
      <TableCell className={classes.noBorder}>{get(decoration, 'size', '')}</TableCell>
      <TableCell className={classes.noBorder}>{get(decoration, 'position', '')}</TableCell>
    </TableRow>
  ));
}

function TableHeaderSmall({children}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <TableCell className={classes.noBorder}>
      <Typography variant={'subtitle2'}
                  className={classes.subHeading}>
        {children}
      </Typography>
    </TableCell>
  );
}

const _JobItem = ({classes, Item, updateItemStatus, updateJobItemVariant}) => {
  const status = Item?.status;
  const [showSubItems, setShowSubItems] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(status || 'IN PROGRESS');

  const handleStatusChange = (newStatus) => {
    updateItemStatus(newStatus);
  };
  useEffect(() => {
    setSelectedStatus(status );
  },[status ]);

  const variants = get(Item, 'jobItemVariant.variants', []);
  const decorations = get(Item, 'jobItemVariant.decorations', []);
  const additionalItems = get(Item, 'jobItemVariant.additionalItems', []);
  return (
    <TableBody>
      <TableRow>
        <TableCell
          className={classnames([classes.itemSummaryRow, !showSubItems ? classes.bottomBorder : null, 'noPrint'])}>
          {Item.jobItemVariant &&
            <ExpandMoreButton
              style={showSubItems ? {transform: 'scale(1, -1)'} : null}
              onClick={() => setShowSubItems((showSubItems) => !showSubItems)}
            />}
        </TableCell>
        <TableCell
          padding={'none'}
          className={classnames([classes.itemSummaryRow, !showSubItems ? classes.bottomBorder : null])}>
          <ImageWithTooltip
            data-intercom-target={'job-item-image'}
            imageUrl={Item.imageUrl}/>
        </TableCell>
        <TableCell className={classnames([classes.itemSummaryRow, !showSubItems ? classes.bottomBorder : null])} colSpan={5}>
          <div dangerouslySetInnerHTML={{__html: Item.description}} />
        </TableCell>
        <TableCell
          className={classnames([classes.itemSummaryRow, !showSubItems ? classes.bottomBorder : null])}>
          {Item.quantity}
        </TableCell>
        <TableCell
          className={classnames([classes.itemSummaryRow, !showSubItems ? classes.bottomBorder : null])}>
          <StatusButton
            statuses={ITEM_STATUSES.map(({name, color}) => ({
              statusLabel: name,
              originalLabel: name,
              color,
            }))}
            rowId={Item._id}
            onSelect={(selected) => {
              setSelectedStatus(selected.statusLabel);
              handleStatusChange(selected.statusLabel);
            }}
            color={ITEM_STATUSES.find(({name}) => name === selectedStatus)?.color}
            text={selectedStatus}/>
        </TableCell>
      </TableRow>
      {/* Variations */}
      {Boolean(showSubItems && variants.length) &&
        <TableRow>
          <TableCell className={classes.noBorder} colSpan={2}/>
          <TableHeaderSmall>Code</TableHeaderSmall>
          <TableHeaderSmall>Product Name</TableHeaderSmall>
          <TableHeaderSmall>Vendor</TableHeaderSmall>
          <TableHeaderSmall>Colour</TableHeaderSmall>
          <TableHeaderSmall>Size</TableHeaderSmall>
          <TableHeaderSmall>Quantity</TableHeaderSmall>
          <TableHeaderSmall>Status</TableHeaderSmall>
        </TableRow>
        }
        {Boolean(showSubItems && variants.length) &&
        <RenderVariations variants={Item.jobItemVariant.variants}
                          data-intercom-target={'job-item-variants'}
                          classes={classes}
                          Item={Item}
                          updateJobItemVariant={updateJobItemVariant}/>
        }
        {/* Decorations */}
        {Boolean(showSubItems && decorations.length) &&
        <TableRow>
          <TableCell className={classes.noBorder}/>
          <TableCell className={classes.noBorder}/>
          <TableCell className={classes.noBorder} colSpan={2}>
            <Typography variant='subtitle2'
                        className={classes.subHeading}>
              Decorations</Typography>
          </TableCell>
          <TableCell className={classes.noBorder} colSpan={2}>
          <Typography variant='subtitle2'
                        className={classes.subHeading}>
              Vendor</Typography>
          </TableCell>
          <TableCell className={classes.noBorder}>
          <Typography variant='subtitle2'
                        className={classes.subHeading}>
              Color(s)</Typography>
          </TableCell>
          <TableCell className={classes.noBorder}>
          <Typography variant='subtitle2'
                        className={classes.subHeading}>
              Size</Typography>
          </TableCell>
          <TableCell className={classes.noBorder}>
          <Typography variant='subtitle2'
                        className={classes.subHeading}>
              Position</Typography>
          </TableCell>
        </TableRow>
        }
        {Boolean(showSubItems && decorations.length) &&
        <RenderDecorations decorations={decorations} classes={classes}/>
        }
        {/* Additional Items */}
        {Boolean(showSubItems && additionalItems.length) &&
        <TableRow>
          <TableCell className={classes.noBorder}/>
          <TableCell className={classes.noBorder}/>
          <TableCell className={classes.noBorder} colSpan={2}>
            <Typography variant='subtitle2'
                        className={classes.subHeading}>
              Additional Items</Typography>
          </TableCell>
          <TableCell className={classes.noBorder} colSpan={2}/>
        </TableRow>
        }
        {Boolean(showSubItems && additionalItems.length) &&
        <RenderDecorations decorations={additionalItems} classes={classes}/>
        }
      </TableBody>
  );
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    updateJobItemVariant: (_id, data) => dispatch(_updateJobItemVariant(_id, data)),
    updateItemStatus: (status) => {
      const {Item, updateJobBoard} = ownProps;
      dispatch(_updateJobItem(Item._id, {...Item, status}), updateJobBoard);
    }
  };
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(_JobItem));
