import React, {useCallback, useMemo, useState} from 'react';
import AddIcon from '@material-ui/icons/AddCircle';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/Brightness1';
import DeleteIcon from '@mui/icons-material/Cancel';
import {HoopsPropTypes} from '../utils';
import {Button, TextInput} from '../Basic';
import {registerGlobalStyle} from '../../theme';
import classNames from 'classnames';
import {Chip} from './Chip';
import {byLocaleCaseInsensitive} from '../../utils';

registerGlobalStyle('.chip-list', (theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  '&.selectable .chip': {
    cursor: 'pointer',
    '&:not(.selected)': {backgroundColor: theme.colors.palette.greyLighter},
  },
  '.chip': {
    svg: {cursor: 'pointer'},
    '&.editable p': {cursor: 'text'},
  },
  '.text-input': {
    height: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  '.button': {
    '*': {alignSelf: 'center'},
    '.button-text': {lineHeight: 'normal'},
  },
}));

export function ChipList({
  className,
  addButtonText,
  allowAddRemove,
  allowRemoveAny,
  allowRenameAny,
  chips,
  color,
  selected,
  sortFunction,
  onChange,
  onChangeSelected,
  onAddChip,
  onRenameChip,
  onRemoveChip,
}) {
  const [editingChip, setEditingChip] = useState('');
  const [addingChip, setAddingChip] = useState(false);
  const [editingValue, setEditingValue] = useState('');

  allowAddRemove = allowAddRemove ?? !!addButtonText;

  const chipList = useMemo(() => {
    const cl = chips ? [...chips] : [];
    selected?.forEach((chip) => {
      if (!cl.includes(chip)) {
        cl.push(chip);
      }
    });
    return sortFunction ? sortFunction(cl) : cl.sort(byLocaleCaseInsensitive);
  }, [chips, selected, sortFunction]);

  const handleClick = useCallback((e) => {
    const chip = e.target.closest('.chip');
    const value = chip?.getAttribute('name');
    if (e.target.closest('svg:last-child')) {
      const pos = chipList.indexOf(value);
      if (pos >= 0) {
        onChange?.(chipList.toSpliced(pos, 1));
        if (onChangeSelected) {
          const selectedPos = selected?.indexOf(value);
          if (selectedPos >= 0) {
            onChangeSelected(selected.toSpliced(pos, 1));
          }
        }
      }
      onRemoveChip?.(value);
    } else if (chip?.classList.contains('editable') && e.target.closest('p') && value) {
      setEditingChip(value);
      setEditingValue(value);
    } else if (onChangeSelected) {
      const pos = selected?.indexOf(value);
      if (pos >= 0) {
        onChangeSelected(selected.toSpliced(pos, 1));
      } else {
        onChangeSelected([...selected ?? [], value]);
      }
    }
  }, [chipList, onChange, onChangeSelected, onRemoveChip, selected]);

  const handleFinishEditing = useCallback((applyChanges, continueEditing) => {
    if (applyChanges && editingValue) {
      if (chipList.includes(editingValue)) {
        return; // If the value is already in the list, just ignore and keep it focused
      }
      if (addingChip) {
        if (onChange) {
          const newChips = [...chips ?? [], editingValue];
          onChange(sortFunction ? sortFunction(newChips) : newChips.sort(byLocaleCaseInsensitive));
        }
        if (onChangeSelected) {
          const newSelected = [...selected ?? [], editingValue];
          onChangeSelected(sortFunction ? sortFunction(newSelected) : newSelected.sort(byLocaleCaseInsensitive));
        }
        onAddChip?.(editingValue);
      } else {
        if (onChange) {
          const newChips = chips?.map((chip) => chip === editingChip ? editingValue : chip) ?? [];
          onChange(sortFunction ? sortFunction(newChips) : newChips.sort(byLocaleCaseInsensitive));
        }
        if (onChangeSelected) {
          const newSelected = selected?.map((chip) => chip === editingChip ? editingValue : chip) ?? [];
          onChangeSelected(sortFunction ? sortFunction(newSelected) : newSelected.sort(byLocaleCaseInsensitive));
        }
        onRenameChip?.(editingChip, editingValue);
      }
    }
    setEditingChip('');
    setAddingChip(!!(continueEditing && editingValue));
    setEditingValue('');
  }, [addingChip, chipList, chips, editingChip, editingValue, onAddChip, onChange, onChangeSelected, onRenameChip, selected, sortFunction]);

  const handleAddChip = useCallback(() => {
    handleFinishEditing(true);
    setEditingChip('');
    setAddingChip(true);
    setEditingValue('');
  }, [handleFinishEditing]);

  const handleEditBlur = useCallback(() => {
    handleFinishEditing(true);
  }, [handleFinishEditing]);

  const handleEditChange = useCallback((e) => {
    setEditingValue(e.target.value);
  }, []);

  const handleEditKeyDown = useCallback((e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.stopPropagation();
      e.preventDefault();
      handleFinishEditing(true, true);
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      handleFinishEditing(false);
    }
  }, [handleFinishEditing]);

  return (
    <div className={classNames([className, 'chip-list', allowAddRemove && 'editable', onChangeSelected && 'selectable'])}>
      {chipList.map((chip) => {
        const isSelected = onChangeSelected && selected && selected.includes(chip);
        const isFixedChip = chips?.includes(chip);
        if (editingChip === chip) {
          return (
            <TextInput value={editingValue} onKeyDown={handleEditKeyDown} onBlur={handleEditBlur} onChange={handleEditChange} autoFocus selectOnFocus autoSize/>
          );
        }
        return (
          <Chip
            className={[isSelected && 'selected', allowAddRemove && (!isFixedChip || allowRenameAny) && 'editable']}
            color={color}
            key={chip}
            name={chip}
            prefix={onChangeSelected && isFixedChip && (isSelected ? CheckedIcon : UncheckedIcon)}
            text={chip}
            suffix={allowAddRemove && (!isFixedChip || allowRemoveAny) && DeleteIcon}
            onClick={handleClick}
          />
        );}
      )}
      {addingChip &&
        <TextInput value={editingValue} onKeyDown={handleEditKeyDown} onBlur={handleEditBlur} onChange={handleEditChange} autoFocus selectOnFocus autoSize/>
      }
      {allowAddRemove &&
        <Button actionPrimary prefix={AddIcon} text={addButtonText ?? 'Add'} onClick={handleAddChip}/>
      }
    </div>
  );
}

ChipList.propTypes = {
  className: HoopsPropTypes.className,
  addButtonText: HoopsPropTypes.string,
  allowAddRemove: HoopsPropTypes.bool,
  allowRemoveAny: HoopsPropTypes.bool, // usually chips in chips can't be removed
  allowRenameAny: HoopsPropTypes.bool, // usually chips in chips can't be renamed
  chips: HoopsPropTypes.arrayOfString,
  color: HoopsPropTypes.string,
  selected: HoopsPropTypes.arrayOfString,
  sortFunction: HoopsPropTypes.func,
  onAddChip: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onChangeSelected: HoopsPropTypes.func,
  onRenameChip: HoopsPropTypes.func,
  onRemoveChip: HoopsPropTypes.func,
};
