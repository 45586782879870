import {Card, Checkbox, Fade, Typography, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import {connect} from 'react-redux';
import {Link, Redirect, Route, Switch, withRouter} from 'react-router-dom';
import hoops_catalog from '../../../assets/images/hoops_catalog.png';
import hoops_proofs from '../../../assets/images/proofs_reg_image.png';

import {SubscriptionTypes} from '../../../componentsHoops';
import {
  FORGOT_PASSWORD_ROUTE,
  INVITED_USER_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  REGISTER_PROOFS_ROUTE,
  REGISTER_XERO_ROUTE, REGISTER_FULLYPROMOTED_ROUTE
} from '../../../containers/routes/PreAuthRoutes';
import {Register} from '../../../pages/RegisterPage';
import Logo from '../../Logo';
import AcceptInvite from '../AcceptInvite';
import ForgotPassword from '../ForgotPassword';
import XeroRegister from '../xero/RegisterXero';
import Login from './Login';

const styles = (theme) => ({
  formContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    height: '100vh',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: theme.spacing(3),
    }
  },

  formSection: {
    maxWidth: 540,
    [theme.breakpoints.down('sm')]: {maxWidth: 800,}
  },

  brandingContainer: {
    height: '100vh',
    color: theme.colors.white,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    justifyContent: 'center',
    overflow: 'hidden',

  },

  deviceLogo: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 50,
    }
  },

  deviceLine: {
    background: theme.palette.primary.main,
    height: 8,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
  },

  deviceWarning: {
    display: 'none',
    '& .MuiSvgIcon-root': {
      color: theme.colors.orange,
      display: 'block',
      margin: '4px auto'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      textAlign: 'center',
      background: `${theme.colors.orange}10`,
      border: `1px solid ${theme.colors.orange}`,
    },
  },

  tagLine: {color: theme.colors.white},

  shapeWhite: {
    width: 58,
    height: 6,
    borderRadius: 15,
    backgroundColor: theme.colors.white,
    marginTop: 8
  },
  shapeGrey: {
    width: 58,
    height: 6,
    borderRadius: 15,
    backgroundColor: theme.colors.grey.light,
    marginTop: 8
  },
  leftColumn: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {display: 'none',}
  },
  rightColumn: {
    backgroundColor: theme.colors.white,
    minHeight: '100vh',
  },

  fullyPromotedProcess: {
    maxWidth: '50%',
    '& ol': {paddingInlineStart: '24px',},
    '& li': {
      fontSize: '1.25em',
      '& p': {fontSize: 'inherit',},
    },
    '& p': {
      marginBottom: '16px',
      fontSize: '1.25em',
    }
  },
});

const StandardBanner = ({classes}) => {
  const WHITE = '#FFF';
  return (
    <Fade
      in={true}
      timeout={{enter: 800,}}>
      <div>
        <Grid item xs={12}>
          <Link to={'/'}>
            <Logo
              fill={[
                WHITE,
                WHITE,
                WHITE
              ]}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>We believe business can be easy</Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.shapeWhite} />
        </Grid>
      </div>
    </Fade>
  );
};

const MarketingBanner = () => (
  <>
    <Grid item xs={12}>
      <Typography variant='h3' align='center' gutterBottom>Create a Hoops Account</Typography>
    </Grid>
    <Grid item xs={12}>

      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
        spacing={1}
        style={{maxWidth: 740, margin: '0 auto'}}
      >
        <Grid item xs={6} style={{display: 'flex'}}>
          <Checkbox checked disabled style={{color: 'white', alignItems: 'flex-start'}} />
          <Typography variant='h6' style={{paddingTop: 4}}>Access demo catalog</Typography>
        </Grid>
        <Grid item xs={6} style={{display: 'flex'}}>
          <Checkbox checked disabled style={{color: 'white', alignItems: 'flex-start'}} />
          <Typography variant='h6' style={{paddingTop: 4}}>Pre-configured to show workflow</Typography>
        </Grid>
        <Grid item xs={6} style={{display: 'flex'}}>
          <Checkbox checked disabled style={{color: 'white', alignItems: 'flex-start'}} />
          <Typography variant='h6' style={{paddingTop: 4}}>Create and send quotes fast</Typography>
        </Grid>
        <Grid item xs={6} style={{display: 'flex'}}>
          <Checkbox checked disabled style={{color: 'white', alignItems: 'flex-start'}} />
          <Typography variant='h6' style={{paddingTop: 4}}>Training, tutorials and support</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <img src={hoops_catalog} width='80%' style={{display: 'block', margin: 'auto', maxWidth: 1348}} alt='Start your free trial now' />
    </Grid>
    </Grid>

  </>

);

const ProofsBanner = () => (
  <>
    <Grid item xs={12}>
      <Typography variant='h3' align='center'>Start Your 3-Day Free Trial</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h5' align='center' gutterBottom>Try Every Feature. No Software to Install. <br />No Credit Card Required. </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body1' align='center' gutterBottom>No more endless threads of emails. No more mistakes.<br/>Your customers will love getting proofs from you.</Typography>
    </Grid>
    <Grid item xs={12}>
      <img src={hoops_proofs} width='80%' style={{display: 'block', margin: 'auto', paddingTop: 24, maxWidth: 600}} alt='Start your free trial now' />
    </Grid>
  </>
);

const FullyPromotedBanner = ({classes}) => (
  <>
    <Grid item xs={12}>
      <Typography variant='h3' align='center'>Sign up for Hoops x Fully Promoted</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='h5' align='center' gutterBottom>No Software to Install. Easy and Fast Registration.</Typography>
    </Grid>
    <Grid item xs={12} style={{maxWidth: '60%'}}>
      <Typography variant='body1' align='center' gutterBottom>
        The future of the Fully Promoted Business Management System is here. No more mistakes. Integration with accounting software & CRM. Simple and Fun Workflow Management.
      </Typography>
    </Grid>
    <Grid className={classes.fullyPromotedProcess} item xs={12}>
      <Typography variant='h6' gutterBottom>
        Here’s the Process:
      </Typography>
      <ol>
        <li>
          <Typography variant='body1' gutterBottom>
            <b>Sign up now</b> - your account has been carefully set up to closely match the Fully Promoted workflow
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            <b>Please go to Settings &gt; Billing and add a credit card to your Hoops account</b>
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            You will be billed for the subscription at $195 per month which includes 3 users. For each additional user from 4-6 users, the cost is $39 per
            month, for user 7 onwards, the cost is $35 per month.
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            You will be billed $1,500 for the 3-month, deluxe Fully Promoted onboarding package
          </Typography>
        </li>
        <li>
          <Typography variant='body1' gutterBottom>
            We will be in touch to confirm which onboarding cohort you will be a part of
          </Typography>
        </li>
      </ol>
      <Typography variant='body1' gutterBottom>
        We’re looking forward to working with you
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        All prices are in AUD and include GST
      </Typography>
    </Grid>
  </>
);

class PublicWrapper extends React.Component {
  render() {
    const {classes} = this.props;

    return (
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item md={8} className={classes.leftColumn}>

          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={classes.brandingContainer}
          >
            <Grid item>
              {/* centering the content */}
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <Switch>
                  <Route path={LOGIN_ROUTE}>
                    <StandardBanner {...this.props} />
                  </Route>
                  <Route exact path={REGISTER_ROUTE}>
                    <MarketingBanner {...this.props} />
                  </Route>
                  <Route path={REGISTER_XERO_ROUTE}>
                    <MarketingBanner {...this.props} />
                  </Route>
                  <Route path={INVITED_USER_ROUTE}>
                    <StandardBanner {...this.props} />
                  </Route>
                  <Route path={REGISTER_PROOFS_ROUTE}>
                    <ProofsBanner {...this.props} />
                  </Route>
                  <Route path={REGISTER_FULLYPROMOTED_ROUTE}>
                    <FullyPromotedBanner {...this.props} />
                  </Route>
                  <Route path={FORGOT_PASSWORD_ROUTE}>
                    <StandardBanner {...this.props} />
                  </Route>
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} className={classes.rightColumn}>

          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={classes.formContainer}
          >
            <Grid item>
              {/* centering the content */}

              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                className={classes.formSection}
              >

                <Switch>
                  <Route path={LOGIN_ROUTE}><Login /></Route>
                  <Route exact path={REGISTER_ROUTE}><Register subscriptionType={SubscriptionTypes.full}/></Route>
                  <Route path={REGISTER_XERO_ROUTE}><XeroRegister /></Route>
                  <Route path={INVITED_USER_ROUTE}><AcceptInvite /></Route>
                  <Route path={REGISTER_PROOFS_ROUTE}><Register subscriptionType={SubscriptionTypes.proofs}/></Route>
                  <Route path={REGISTER_FULLYPROMOTED_ROUTE}><Register subscriptionType={SubscriptionTypes.fullyPromoted}/></Route>
                  <Route path={FORGOT_PASSWORD_ROUTE}><ForgotPassword /></Route>
                  <Redirect to={LOGIN_ROUTE} />
                </Switch>
              </Grid>
            </Grid>

            <Card variant='outlined' className={classes.deviceWarning}>
              <WarningIcon fontSize='large' />
              <Typography variant='caption'>It looks like you’re on a small device. Hoops is not optimised for mobile devices. For the best experience, we recommend that you use a larger screen. </Typography>
            </Card>

          </Grid>

        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.authReducer};
}

export default withRouter(connect(mapStateToProps)(
  withStyles(styles, {withTheme: true})(PublicWrapper)
));
