import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAnonGetSalesDoc} from '../../../hooks/api';
import {SalesDoc} from '../Models/SalesDoc';

export function useSalesDocGuestState(salesDocId) {
  const {data: {salesDoc: apiSalesDoc}, isLoading: salesDocLoading, refetch: refetchSalesDoc} = useAnonGetSalesDoc(salesDocId);

  const [salesDoc, _setSalesDoc] = useState();

  // Whenever the SalesDoc is set, it will be bound to the salesDoc state
  const setSalesDoc = useCallback((newSalesDoc) => {
    _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));
  }, []);

  // This effect handles the initial load, loading a SalesDoc from the server
  useEffect(() => {
    if (apiSalesDoc) {
      const newSalesDoc = SalesDoc.fromApi(apiSalesDoc);
      _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSalesDoc]);

  return useMemo(() => ({
    isLoading: salesDocLoading,
    salesDoc,
    salesDocId,
    refetchSalesDoc,
  }), [salesDocLoading, salesDoc, salesDocId, refetchSalesDoc]);
}
