import React, {useCallback} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {useSnackbar} from '../../hooks';
import {HoopsPropTypes} from '../utils';
import {filterFiles, MaxFileSize} from '../../utils';

registerGlobalStyle('body:has(.drop-zone)', (theme) => ({
  '.drop-zone': {
    position: 'relative',
    '&>.drop-zone-indicator': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '3rem',
      color: theme.colors.transparent,
      pointerEvents: 'none',
      outline: '2px dashed transparent ',
      outlineOffset: 2,
      borderRadius: theme.shape.borderRadius,
      transition: `${theme.transitions.out.outline}, ${theme.transitions.out.all}`,
    }
  },
  '&.dragging-files .drop-zone-indicator': {transition: `${theme.transitions.in.outline}, ${theme.transitions.in.all}`,},
  [
    '&.dragging-single-image .drop-single-image > .drop-zone-indicator,' +
    '&.dragging-multi-image .drop-multi-image > .drop-zone-indicator,' +
    '&.dragging-single-file .drop-single-file > .drop-zone-indicator,' +
    '&.dragging-multi-file .drop-multi-file > .drop-zone-indicator'
  ]: {
    backgroundColor: theme.colors.background.backdropWhite,
    outlineColor: theme.colors.border.main,
    color: theme.colors.text.medium,
  },
}));

export function DropZone({className, accept, image, multi, text, maxSize, onDrop, children}) {
  const snack = useSnackbar();

  let typeClass;
  if (image && multi) {
    text = text ?? 'Drop image files here';
    typeClass = 'drop-multi-image';
  } else if (image && !multi) {
    text = text ?? 'Drop image file here';
    typeClass = 'drop-single-image';
  } else if (!image && multi) {
    text = text ?? 'Drop files here';
    typeClass = 'drop-multi-file';
  } else if (!image && !multi) {
    text = text ?? 'Drop file here';
    typeClass = 'drop-single-file';
  }

  const handleDragOver = useCallback((e) => {
    e.dataTransfer.dropEffect = 'copy';
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    onDrop?.(filterFiles([...e.dataTransfer.files], {maxSize, accept: image ? `image/*${accept ? ',' + accept : ''}` : null, multi}, snack));
  }, [accept, image, maxSize, multi, onDrop, snack]);

  return (
    <div className={classNames([className, 'drop-zone', typeClass])} onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className={'drop-zone-indicator'}>
        {text}
      </div>
      {children}
    </div>
  );
}

DropZone.propTypes = {
  accept: HoopsPropTypes.string,
  className: HoopsPropTypes.className,
  image: HoopsPropTypes.bool,
  maxSize: HoopsPropTypes.oneOf(Object.values(MaxFileSize)),
  multi: HoopsPropTypes.bool,
  onDrop: HoopsPropTypes.func,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
