// TODO: fix eslint disable
/* eslint-disable consistent-return */

import React, {useCallback, useEffect, useState} from 'react';
import {createGraphQLClient} from '../../servicesOld/GraphQLService';
import pluralize from 'pluralize';
import HoopsFormDialog from '../modal/HoopsFormDialog';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {setModalOpen} from '../../actions/modals';
import {Typography} from '@material-ui/core';
import {Field, Form} from 'react-final-form';
import {MultipleAutocompleteField} from '../shared/formHelpers/fields';
import {GQL_GET_COMPANIES} from '../../queries/companies';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import Button from '@material-ui/core/Button';
import {
  GQL_GET_CATALOG_IMPORTS,
  GQL_SHARE_CATALOG_IMPORT,
} from '../../queries/catalogImport';
import {GQL_GET_PRODUCT, GQL_SHARE_PRODUCT} from '../../queries/product';
import RenderCatalogImport from './shareProductModal/RenderCatalogImport';
import RenderProduct from './shareProductModal/RenderProduct';
import {HoopsButton} from '../../componentsLib';
import ContentCopyIcon from '@mui/icons-material/CopyAll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {useLocalStorageState} from '../../hooks';

// TODO: Why is this here?
const client = createGraphQLClient({});

const TitleComponent = ({context, catalogImport, catalogImports, product}) => {
  if (context.catalogImportIds && catalogImports.length) {
    return (<>
      <Grid container direction={'column'}>
        <Grid item>
          <Typography variant={'h4'}>Share Catalog Imports</Typography>
        </Grid>
      </Grid>
    </>);
  }

  if (context.catalogImportId && catalogImport._id) {
    return (<>
      <Grid container direction={'column'}>
        <Grid item>
          <Typography variant={'h4'}>Share Catalog Import</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h6'}
            color={'textSecondary'}>{catalogImport.vendor.name} - {catalogImport.numberProducts} {pluralize('Product', catalogImport.numberProducts)}</Typography>
        </Grid>
      </Grid>
    </>);
  }

  return (<>
    {!!product._id && <Grid container direction={'column'}>
      <Grid item>
        <Typography variant={'h4'}>Share Product</Typography>
      </Grid>
      <Grid item>
        <Typography variant={'h6'} color={'textSecondary'}>
          {product.title}</Typography>
        <Typography variant={'subtitle1'} color={'primary'}>{product.vendor.name}</Typography>
      </Grid>
    </Grid>}
  </>);

};

export default function ShareProductModal() {
  const dispatch = useDispatch();
  const modalSelector = (state) => state.modalReducer.shareProductModal;
  const userDataSelector = (state) => state.authReducer;
  const {open, context} = useSelector(modalSelector);
  const {userData} = useSelector(userDataSelector);
  const [companies, setCompanies] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [catalogImport] = useState({});
  const [catalogImports, setCatalogImports] = useState([{}]);
  const [product, setProduct] = useState({});
  const [copiedCompanies, setCopiedCompanies] = useLocalStorageState('importCatalogCompanies', []);
  // TODO: Why are we doing this here?
  const {data: companyMany} = useQuery(GQL_GET_COMPANIES, {
    client,
    fetchPolicy: 'network-only',
    variables: {limit: 3000}
  });

  const [getCatalogImports, {
    data: catalogImportsData, refetch: refetchCatalogImports,
    loading: loadingCatalogImports
  }] = useLazyQuery(GQL_GET_CATALOG_IMPORTS, {
    client: client,
    fetchPolicy: 'cache-and-network',
    variables: {_id: context.catalogImportIds,},
    onCompleted: (result) => setCatalogImports(result.catalogImports),
  });

  const [getProductData, {refetch: refetchProduct}] = useLazyQuery(GQL_GET_PRODUCT, {
    client,
    fetchPolicy: 'cache-and-network',
    variables: {_id: context.productId,},
    onCompleted: (result) => {
      if (result.product) {
        setProduct(result.product);
      }
    },
  });

  const [shareCatalogImport, {loading}] = useMutation(GQL_SHARE_CATALOG_IMPORT);

  const [shareProduct] = useMutation(GQL_SHARE_PRODUCT,
    {
      client,
      onCompleted: () => refetchProduct(),
    },
  );

  const handleCancel = useCallback(() => {
    dispatch(setModalOpen('shareProductModal', false));
  }, [dispatch]);

  useEffect(() => {
    if (context.catalogImportId) {
      setViewers(catalogImport.viewOnlyCompanies || []);
    }
  }, [context, catalogImport]);

  useEffect(() => {
    if (context.productId) {
      setViewers(product.viewOnlyCompanies || []);
    }
  }, [context, product]);

  useEffect(() => {
    if (context.productId) {
      getProductData();
    }
  }, [context, getProductData]);

  useEffect(() => {
    if (context.catalogImportIds) {
      getCatalogImports();
    }
  }, [context, getCatalogImports]);

  useEffect(() => {
    if (companyMany && companyMany.companyMany && userData) {
      const companyArray = companyMany.companyMany.map((company) => ({_id: company._id, name: company?.companyTradingEntities[0]?.name + ' - ' + company?.companyTradingEntities[0]?.email}));
      setCompanies(companyArray.filter((company) => company._id !== userData.companyId && company?.name?.length > 0));
    }
  }, [companyMany, userData, viewers]);

  const handleOnSubmit = useCallback((values) => {
    if (!values.viewers.length) {
      handleCancel();
      return;
    }

    const companyIds = values.viewers.map((viewer) => viewer._id);
    if (context.catalogImportIds) {
      return Promise.all(context.catalogImportIds.map((_id) => shareCatalogImport({
        variables: {
          _id,
          companyIds,
        },
      })))
        .then(() => refetchCatalogImports());
    }
    return shareProduct({
      variables: {
        _id: context.productId,
        companyIds,
      },
    });
  }, [handleCancel, shareProduct, context, refetchCatalogImports, shareCatalogImport]);

  const handleCopy = () => {
    setCopiedCompanies(catalogImportsData.catalogImports[0].viewOnlyCompanies);
  };

  return (
    <>
      <Form
        mutators={{
          pasteValues: (args, state, utils) => {
            utils.changeValue(state, 'viewers', () => copiedCompanies.map((company) => ({_id: company._id, name: company?.companyTradingEntities[0]?.name})));
          },
        }}
        onSubmit={handleOnSubmit}
        initialValues={{viewers: []}}
        render={({handleSubmit, values, form}) => (
          <>
            <HoopsFormDialog
              TitleComponent={<TitleComponent context={context} catalogImport={catalogImport} product={product} catalogImports={catalogImports} />}
              open={open}
              onCancel={handleCancel}
              SaveButton={() => (
                <>
                  <HoopsButton purpose='menu' sx={{mr: 1}} startIcon={<ContentPasteIcon />} disabled={copiedCompanies.length === 0} onClick={form.mutators.pasteValues}>Paste Companies</HoopsButton>
                  <HoopsButton purpose='menu' sx={{mr: 1}} startIcon={<ContentCopyIcon />} onClick={handleCopy}>Copy Companies</HoopsButton>
                  <Button variant={'contained'} color={'primary'} type={'submit'} disabled={loading || values.viewers.length === 0}
                    onClick={() => form.submit()}>
                    Save
                  </Button>
                </>
              )}
            >
              <>
                <Grid container direction={'column'} spacing={3}>
                  {context.productId ?
                    <Grid item xs={12}>
                      <RenderProduct product={product} onUpdate={refetchProduct} />
                    </Grid> :
                    catalogImportsData && catalogImportsData.catalogImports.map((cImport) =>
                      <Grid item key={cImport._id} xs={12}>
                        <RenderCatalogImport catalogImport={cImport}
                          onUpdate={refetchCatalogImports}
                          loading={loadingCatalogImports}
                        />
                      </Grid>
                    )
                  }
                </Grid>

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Field
                        name='viewers'
                        blurOnSelect
                        component={MultipleAutocompleteField}
                        autoSelect
                        options={companies}
                        helperText={'Select a company to share this import. These companies will only have READ access to products.'}
                        label='Companies'
                        primaryKey={'_id'}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </form>
              </>
            </HoopsFormDialog>
          </>
        )} />
    </>
  );
}
