import React, {useCallback, useState} from 'react';
import {TextInput} from '.';
import {asRounded, asRoundedString} from '../../utils';
import {HoopsPropTypes} from '../utils';

export function DecimalInput({className, label, minDecimals = 2, maxDecimals = 6, onBlur, onChange, onFocus, step, value, ...props}) {
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(asRoundedString(value, minDecimals, maxDecimals));

  const handleChange = useCallback((e) => {
    if (asRoundedString(value, minDecimals, maxDecimals) !== e.target.value) {
      onChange?.(asRounded(e.target.value, maxDecimals));
    }
    setInputValue(e.target.value);
  }, [maxDecimals, minDecimals, onChange, value]);

  const handleFocus = useCallback((e) => {
    setFocused(true);
    onFocus?.(e);
  }, [onFocus]);

  const handleBlur = useCallback((e) => {
    setFocused(false);
    setInputValue(asRoundedString(inputValue, minDecimals, maxDecimals));
    onBlur?.(e);
  }, [inputValue, maxDecimals, minDecimals, onBlur]);

  if (!focused || asRounded(value, maxDecimals) !== asRounded(inputValue, maxDecimals)) {
    value = value == null ? '' : asRoundedString(value, minDecimals, maxDecimals);
  } else {
    value = inputValue;
  }

  return (
    <TextInput
      className={[className, 'number-input']}
      type={'number'}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      label={label}
      value={value}
      step={step}
      {...props}
    />);
}

DecimalInput.propTypes = {
  className: HoopsPropTypes.className,
  autoSize: HoopsPropTypes.bool,
  blurOnEnterEscape: HoopsPropTypes.bool,
  clearable: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  minDecimals: HoopsPropTypes.number,
  maxDecimals: HoopsPropTypes.number,
  onBlur: HoopsPropTypes.func,
  onChange: HoopsPropTypes.func,
  onClear: HoopsPropTypes.func,
  onFocus: HoopsPropTypes.func,
  prefix: HoopsPropTypes.any,
  readOnly: HoopsPropTypes.bool,
  step: HoopsPropTypes.number,
  suffix: HoopsPropTypes.any,
  value: HoopsPropTypes.stringOrNumber,
};
