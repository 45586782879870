import React, {createContext, useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import NewIcon from '@mui/icons-material/NewReleases';
import ThumbsUp from '@mui/icons-material/ThumbUpAlt';
import {Collapse, Fade} from '@mui/material';
import {Row} from '../../componentsLib/Layout';
import {registerGlobalStyle} from '../../theme';
import {Button} from '../../componentsLib/Basic';
import {useLocalStorageState} from '../../hooks';
import {FeatureFlag} from '../AccessControl';
import {featureFlags} from '../../utils/featureFlag';
import {HoopsPropTypes} from '../../componentsLib';
import {BodyText} from '../../componentsLib/Text';
import {getLocalStorageItem} from '../../utils';

registerGlobalStyle('.salesboard-banner', (theme) => ({
  position: 'relative',
  background: theme.colors.palette.purple,
  color: theme.colors.text.contrast,
  zIndex: theme.zIndex.appBar,
  '.MuiCollapse-root': {width: '100%'},
  '.text-body': {
    color: theme.colors.text.contrast,
    fontSize: '1rem',
    flexGrow: 1,
  },
  '.got-it > svg': {
    alignSelf: 'end',
    position: 'relative',
    top: -3,
    fontSize: '1.5rem',
  },
  'button.button.close': {
    position: 'relative',
    top: -2,
    color: theme.colors.text.contrast,
    svg: {fontSize: '1.5rem'},
    padding: theme.spacing(.5),
    borderRadius: '50%',
  },
  '.button': {
    textTransform: 'uppercase',
    color: theme.colors.text.contrast,
    borderColor: theme.colors.transparent,
    backgroundColor: theme.colors.transparent,
    '&:hover': {
      color: theme.colors.text.contrast,
      borderColor: theme.colors.text.contrast,
      backgroundColor: theme.colors.alpha.darker,
    },
    boxShadow: 'none',
  },
  '&.old': {
    width: 'calc(100% + 48px)',
    position: 'relative',
    left: -24,
    top: -24,
    flexShrink: 0,
  },
}));

registerGlobalStyle('.show-salesboard-button', (theme) => ({
  display: 'flex',
  button: {
    color: theme.colors.palette.purple,
    svg: {fontSize: '1.5rem'},
  },
  '&.old button svg': {fontSize: '2rem'}
}));

export function SalesBoardBanner() {
  const {alwaysHideBanner, setAlwaysHideBanner, setPreferNewQuoteBuilder, usingNewQuoteBuilder} = useNewQuoteBuilderBannerContext();
  const history = useHistory();

  const handleSwitchEditor = useCallback(() => {
    if (usingNewQuoteBuilder) {
      history.replace('/quotes');
    } else {
      history.replace('/sales');
    }
  }, [history, usingNewQuoteBuilder]);

  const handleGotIt = useCallback(() => {
    setPreferNewQuoteBuilder(usingNewQuoteBuilder);
    setAlwaysHideBanner(true);
  }, [usingNewQuoteBuilder, setAlwaysHideBanner, setPreferNewQuoteBuilder]);

  return (
    <FeatureFlag allow={featureFlags.newQuoteBuilder} disallow={featureFlags.disallowOldQuoting}>
      <Row className={usingNewQuoteBuilder ? 'salesboard-banner new' : 'salesboard-banner old'}>
        <Collapse in={!alwaysHideBanner}>
          <Row className={'banner-content'} alignCenter pad gap>
            <BodyText>
              We&apos;ve made it easier than ever to create beautiful quotes for your customers. We hope you like SalesBoard and the new editor, but you can continue to use classic quoting if you prefer.
            </BodyText>
            <Button className={'got-it'} unstyled onClick={handleGotIt} text={'Got it'} suffix={ThumbsUp} noWrap/>
            <Button unstyled onClick={handleSwitchEditor} noWrap>
              {usingNewQuoteBuilder ? 'Use classic Quoting' : 'Use SalesBoard'}
            </Button>
          </Row>
        </Collapse>
      </Row>
    </FeatureFlag>
  );
}

export function ShowSalesBoardBannerButton() {
  const {alwaysHideBanner, setAlwaysHideBanner, usingNewQuoteBuilder} = useNewQuoteBuilderBannerContext();

  const handleShowBanner = useCallback(() => {
    setAlwaysHideBanner(false);
  }, [setAlwaysHideBanner]);

  return (
    <FeatureFlag allow={featureFlags.newQuoteBuilder} disallow={featureFlags.disallowOldQuoting}>
      <Fade in={alwaysHideBanner}>
        <div className={`show-salesboard-button ${usingNewQuoteBuilder ? 'new' : 'old'}`}>
          <Button onClick={handleShowBanner} prefix={NewIcon}/>
        </div>
      </Fade>
    </FeatureFlag>
  );
}

const Context = createContext(null);
const useNewQuoteBuilderBannerContext = () => useContext(Context);

export function isUsingSalesBoard() {
  return getLocalStorageItem('salesboard|preferSalesBoard', true);
}

export function NewQuoteBuilderBannerProvider({children}) {
  const [alwaysHideBanner, setAlwaysHideBanner] = useLocalStorageState('salesboard|alwaysHideBanner', true);
  const [preferNewQuoteBuilder, setPreferNewQuoteBuilder] = useLocalStorageState('salesboard|preferSalesBoard', true);

  const value = useMemo(() => ({
    alwaysHideBanner,
    setAlwaysHideBanner,
    preferNewQuoteBuilder,
    setPreferNewQuoteBuilder,
    usingNewQuoteBuilder: window.location.pathname.includes('sales'),
  }), [alwaysHideBanner, preferNewQuoteBuilder, setAlwaysHideBanner, setPreferNewQuoteBuilder]);
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

SalesBoardBanner.propTypes = {};
ShowSalesBoardBannerButton.propTypes = {};
NewQuoteBuilderBannerProvider.propTypes = {children: HoopsPropTypes.any.isRequired,};
