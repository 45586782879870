// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Button, CircularProgress, Fade, Grid, makeStyles, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import React, {useEffect, useState} from 'react';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import HoopsFormDialog from '../../componentsOld/modal/HoopsFormDialog';
import theme from '../../ui/theme';
import {integrations} from '../../ui/theme/integrations';
import IntegrationService from './IntegrationService';

const useStyles = makeStyles((theme) => (
  {
    ...integrations,
    iconStyle: {
      fontSize: '16px',
      color: theme.palette.secondary.main,
    },
    iconButton: {
      padding: '0 0 2px 0',
      marginLeft: '11px'
    },
    apiKey: {fontWeight: 500}
  }
));

export const SageContent = ({config}) => (
    <>

    </>
  );

export const SageButton = ({config = {}}) => {

  const classes = useStyles();

  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [connectionData, setConnectionData] = useState(config ? {_id: config._id, status: config.status, accountId: config.accountId} : {});

  useEffect(() => {
    setConnectionData({_id: config?._id, status: config?.status, accountId: config?.accountId});
  }, [config?._id, config?.status, config?.accountId]);

  const handleTestCredentials = async () => {
    setLoading(true);
    setTestResults(null);

    IntegrationService.testIntegration({type: 'sage', accountId: credentials.accountId, accountKey: credentials.accountKey, username: credentials.username})
      .then((response) => {
        setTestResults(true);
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setTestResults(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveCredentials = () => {
    setLoading(true);
    setErrorMessage('');

    IntegrationService.createIntegration({type: 'sage', accountId: credentials.accountId, accountKey: credentials.accountKey, username: credentials.username})
      .then((response) => {
        setConnectionData({_id: response.record._id, status: response.record.status, accountId: response.record.accountId});
        handleClose();
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });

    setLoading(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = () => {
    IntegrationService.deleteIntegration(connectionData._id)
      .then(() => {
        setConnectionData({});
      });
  };

  return (
    <>
      <HoopsFormDialog
        open={dialogOpen}
        title={'Connect SAGE'}
        onCancel={() => handleClose()}
        DialogActionComponent={
          <Grid container
            justifyContent={'space-between'}
          >
            <Grid item>
              <Button variant={'text'}
                color={'secondary'}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>

              <Button
                variant='contained'
                color='primary'
                disabled={!testResults}
                disableElevation
                onClick={handleSaveCredentials}
              >
                Connect
              </Button>

            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          style={{maxWidth: 500, margin: 'auto'}}
        >

          <Grid item xs={12}>
            <Typography variant='body2' className={classes.greyText} align='center'>Enter Your Sage Account Credentials</Typography>
            <Typography variant='body2' className={classes.greyText} align='center' style={{fontSize: '.75rem'}}>
              To connect to SAGE you must enter your <b>account number</b>, which can be found on the top right side of the screen when logged into the SAGE web site;
              and your <b>authentication key</b>, which can be found under the menu Developer APIs &gt; SAGE Connect on the Configuration tab
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <HoopsTextField
              label='Account Number'
              name='accountId'
              onChange={(e) => { const value = e.target.value; setCredentials((prev) => ({...prev, accountId: value})); }}

            />
          </Grid>
          <Grid item xs={8}>
            <HoopsTextField
              label='Login ID'
              name='username'
              onChange={(e) => { const value = e.target.value; setCredentials((prev) => ({...prev, username: value})); }}
            />
          </Grid>
          <Grid item xs={8}>
            <HoopsTextField
              label='Authentication Key'
              name='accountKey'
              type='password'
              autoComplete='new-password'
              inputProps={{autoComplete: 'new-password'}}
              onChange={(e) => { const value = e.target.value; setCredentials((prev) => ({...prev, accountKey: value})); }}
            />
          </Grid>

          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Fade in={loading} out={loading}>
              <div>
                {
                  loading &&
                  <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.greyText}>
                    {testResults
                      ? 'Setting up connection please wait...'
                      : 'Testing account credentials please wait...'
                    }
                  </Typography>
                }
              </div>
            </Fade>

            <Fade in={testResults !== null}>

              <div>
                {/* Test success */}
                {!loading && testResults === true && !(connectionData && connectionData._id) &&
                  <>
                    <CheckCircleIcon className={classes.green} />
                    <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.green}>Success!</Typography>
                  </>
                }
                {/* Test failed*/}
                {!loading && testResults === false && !(connectionData && connectionData._id) &&
                  <>
                    <ErrorIcon className={classes.red} />
                    <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.red}>{errorMessage}</Typography>
                  </>
                }
              </div>
            </Fade>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'center'}}>

            <Button variant='outlined' color='primary' disabled={loading} onClick={handleTestCredentials}>
              {loading && !testResults ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Test Credentials</> : 'Test Credentials'}
            </Button>
          </Grid>
        </Grid>
      </HoopsFormDialog>

      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <Grid item>
          <Typography variant='body2' className={classes.greyText}>
            {
              connectionData && connectionData.status && (
                connectionData.accountId
                  ? `Connection ${connectionData.status} for account ${connectionData.accountId}`
                  : `Connection ${connectionData.status}`)
            }
          </Typography>
        </Grid>
        <Grid item>
          {
            (connectionData && connectionData._id)
              ? <Button variant='outlined' color='primary' disabled={loading} onClick={() => handleDelete()}>
                {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Disconnect</> : 'Disconnect'}
              </Button>
              : <Button variant='contained' color='primary' disabled={loading} onClick={() => setDialogOpen(true)}>
                {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Connect</> : 'Connect'}
              </Button>

          }
        </Grid>
      </Grid>
    </>
  );
};
