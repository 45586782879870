import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {BodyText, CaptionText} from '../Text';
import {flattenChildren, HoopsPropTypes} from '../utils';

registerGlobalStyle('.table', (theme) => ({
  borderCollapse: 'collapse',
  borderStyle: 'solid',
  borderColor: theme.colors.border.light,
  border: 'none',
  alignSelf: 'stretch',

  'th': {
    position: 'relative',
    background: 'none',
    border: 'none',
    textAlign: 'left',
    paddingLeft: 0,
    paddingBottom: 0,
    '.text-caption': {
      fontSize: '.75rem',
      fontWeight: theme.typography.light,
    }
  },
  'td': {
    position: 'relative',
    '.table-data-cell-text': {textAlign: 'left',},
    '.input-outline': {
      padding: theme.spacing(1, 1.75),
      border: 'none',
    },
    '.autocomplete .suffix': {display: 'none'},
  },

  '&.body-border': {
    'tbody': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        pointerEvents: 'none',
        inset: '0 0 0 0',
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.colors.border.light}`,
        backgroundColor: 'transparent',
        zIndex: '1',
      },
    },
    'td': {
      borderRadius: 0,
      border: `1px solid ${theme.colors.border.lightest}`,
    },
    'tr:first-child td': {
      borderTop: 'none',
      '&:first-child': {borderTopLeftRadius: theme.shape.borderRadius},
      '&:last-child': {borderTopRightRadius: theme.shape.borderRadius},
    },
    'tr:last-child td': {
      borderBottom: 'none',
      '&:first-child': {borderBottomLeftRadius: theme.shape.borderRadius},
      '&:last-child': {borderBottomRightRadius: theme.shape.borderRadius},
    },
    'tr td:first-child': {borderLeft: 'none'},
    'tr td:last-child': {borderRight: 'none'},
  },
}));

export const Table = forwardRef(
function Table({className, bodyBorder, labels, sizes, children, ...props}, ref) {
  let heading;
  if (!labels && children.length > 0) {
    if (children[0].type.name === TableHeading.prototype.constructor.name) {
      [heading, ...children] = children;
    }
  }

  return (
    <table className={classNames([className, 'table', bodyBorder && 'body-border'])} {...props} ref={ref}>
      {labels &&
        <>
          <thead>
            <tr>
              {labels.map((label, index) => (
                <th key={index} {...{...(sizes ? {style: {width: sizes[index]}} : {})}}>
                  <CaptionText>{label}</CaptionText>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {children}
          </tbody>
        </>
      }
      {heading}
      {!labels &&
        <tbody>
          {children}
        </tbody>
      }
    </table>
  );
});

Table.propTypes = {
  className: HoopsPropTypes.className,
  bodyBorder: HoopsPropTypes.bool,
  labels: HoopsPropTypes.arrayOfString,
  sizes: HoopsPropTypes.arrayOf(HoopsPropTypes.oneOfType([HoopsPropTypes.string, HoopsPropTypes.number])),
  onMouseEnter: HoopsPropTypes.func,
  onMouseLeave: HoopsPropTypes.func,
  onMouseOut: HoopsPropTypes.func,
  onMouseOver: HoopsPropTypes.func,
  children: HoopsPropTypes.children
};

export function TableHeading({children}) {
  return (
    <thead>
      <tr>
        {children}
      </tr>
    </thead>);
}

TableHeading.propTypes = {children: HoopsPropTypes.children};

export function TableHeadingCell({text, width, children}) {
  return (
    <th style={width ? {width} : undefined}>
      {text && <CaptionText className={'table-data-cell-text'}>{text}</CaptionText>}
      {children}
    </th>
  );
}

TableHeadingCell.propTypes = {
  text: HoopsPropTypes.string,
  width: HoopsPropTypes.number,
  children: HoopsPropTypes.children,
};

export const TableRow = forwardRef(
function TableRow({className, children, ...props}, ref) {
  return (
    <tr className={classNames([className, 'table-row'])} {...props} ref={ref}>
      {flattenChildren(children).map((child, index) => {
        if (child.type?.name === TableCell.prototype.constructor.name) {
          return child;
        } else {
          return <TableCell key={child.props.key ?? index}>{child}</TableCell>;
        }
      })}
    </tr>
  );
});

TableRow.propTypes = {
  className: HoopsPropTypes.className,
  children: HoopsPropTypes.children
};

export function TableCell({className, text, colSpan, rowSpan, children}) {
  return (
    <td className={classNames([className, 'table-data-cell'])} colSpan={colSpan} rowSpan={rowSpan}>
      {children ?? <BodyText className={'table-data-cell-text'}>{text}</BodyText>}
    </td>
  );
}

TableCell.propTypes = {
  className: HoopsPropTypes.className,
  colSpan: HoopsPropTypes.number,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children
};
