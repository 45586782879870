import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Icon} from '@mui/material';
import copy from 'copy-to-clipboard';
import {Button, Link, ToolTip} from '../../../componentsLib/Basic';
import {AttentionConfirmDialog, Placement, PopupItem, PopupMenuList, usePopupMenuContext} from '../../../componentsLib/Popovers';
import {useCompany, useMountEffect, useSnackbar} from '../../../hooks';
import {SubscriptionTypes, permissionCheck, FeatureFlag, FeatureFlags} from '../../../componentsHoops';
import {BodyText} from '../../../componentsLib/Text';
import {useDeleteSalesDoc, useFetchSalesDoc} from '../../../hooks/api';
import {editNewTask} from '../../../actions/tasks';
import {SendSalesDocModal} from '../../../componentsHoops/Modals/SendSalesDocModal';
import {SalesDocGuestPage} from '../Pages/SalesDocGuestPage';
import {Offscreen} from '../../../componentsLib/Layout';
import {convertToJob} from '../../../actions/quote';
import {draftInvoiceFromQuote, getInvoice} from '../../../actions/invoice';
import {SalesDoc} from '../Models/SalesDoc';

export function SalesDocActionMenu({salesDoc: _salesDoc, updateField, refetchSalesDocs}) {
  const [fetchedSalesDoc, setFetchedSalesDoc] = useState(null);
  const salesDoc = fetchedSalesDoc ?? _salesDoc;
  const history = useHistory();
  const dispatch = useDispatch();
  const {delete: deleteSalesDoc} = useDeleteSalesDoc();
  const snack = useSnackbar();
  const {closeMenu} = usePopupMenuContext();
  const [accountingConnected, setAccountingConnected] = useState(false);
  const [archived, setArchived] = useState(salesDoc?.archived);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [showGuestView, setShowGuestView] = useState(false);
  const {fetch: fetchSalesDocApi} = useFetchSalesDoc();
  const {company} = useCompany(salesDoc?.companyId);
  const isPresentation = salesDoc?.documentType === SalesDoc.Type.PRESENTATION;

  useMountEffect(() => {
    (async () => {
        if (!fetchedSalesDoc) {
          const fetched = await fetchSalesDocApi(salesDoc._id);
          setFetchedSalesDoc(fetched?.salesDoc);
          if (fetched?.salesDoc?.invoice?.status !== salesDoc.invoice?.status) {
            refetchSalesDocs();
          }
        }
      }
    )();
  });

  useEffect(() => {
    const refreshTokenExpiry = Number.parseInt(company?.accountingPlatform?.refreshTokenExpiry ?? '0');
    if (refreshTokenExpiry > new Date().getTime()) {
      setAccountingConnected(true);
    }
  }, [company]);

  const handleAddTask = useCallback(() => {
    dispatch(editNewTask({
      quoteIds: [salesDoc._id],
      quotes: [salesDoc]
    }));
  }, [dispatch, salesDoc]);

  const handleConvertToJob = () => {
    dispatch(convertToJob(salesDoc._id));
    history.push('/jobs/new');
  };

  const handleCreateEditInvoice = () => {
    const invoiceId = salesDoc.invoice?._id;
    if (!invoiceId) {
      dispatch(draftInvoiceFromQuote(salesDoc._id));
    } else if (company.accountingPlatform?.platform !== 'XERO' || salesDoc.invoice?.balance === salesDoc.invoice?.totalAmount) {
      dispatch(getInvoice(invoiceId));
    }
  };

  const handleChangeArchived = (newValue) => {
    setArchived(newValue);
    updateField({id: salesDoc._id, field: 'archived', value: newValue});
  };

  const handleCopyCustomerLink = useCallback(() => {
    copy(`${window.location.origin}/public/sales/${salesDoc._id.toString()}/view`);
    snack.showSnackbarSuccess('Link copied.');
  }, [salesDoc, snack]);

  const handleShowConfirmDelete = useCallback(() => setConfirmDelete(true), []);

  const handleDelete = useCallback(async (confirmed) => {
    setConfirmDelete(false);
    closeMenu();
    if (confirmed && confirmDelete) {
      await deleteSalesDoc({id: salesDoc._id});
      refetchSalesDocs();
      history.push('/sales');
    }
  }, [closeMenu, confirmDelete, deleteSalesDoc, salesDoc._id, refetchSalesDocs, history]);

  const handleShowSend = useCallback(async () => {
    setShowSend(true);
  }, []);

  const handleCloseSend = useCallback(() => {
    setShowSend(false);
    closeMenu();
  }, [closeMenu]);

  const handlePrintPdf = useCallback(() => {
    setShowGuestView(true);
  }, []);

  const handlePrintPdfComplete = useCallback(() => {
    setShowGuestView(false);
    closeMenu();
  }, [closeMenu]);

  const permissionFullyPromoted = permissionCheck({disallowedSubscriptions: [SubscriptionTypes.fullyPromoted]});
  const invoicePdfLink = salesDoc.invoice?._id && `${process.env.REACT_APP_BASE_URL}/rest/accounting/invoices/${salesDoc.invoice?._id}/pdf`;
  const invoiceLocked = company.accountingPlatform?.platform === 'XERO' && salesDoc.invoice && salesDoc.invoice.balance !== salesDoc.invoice.totalAmount;
  const onJobBoard = window.location.pathname.includes('/jobs/');

  return (
    <PopupMenuList className={'salesdoc-action-menu'}>
      <FeatureFlag allow={FeatureFlags.newQuoteBuilder}>
        <PopupItem>
          <Link to={`/sales/edit/${salesDoc.number}`}>
            <Icon>edit</Icon>
            <BodyText>Edit</BodyText>
          </Link>
        </PopupItem>
      </FeatureFlag>
      <FeatureFlag disallow={FeatureFlags.newQuoteBuilder}>
        <PopupItem>
          <Link to={`/quotes/${salesDoc.number}/edit`}>
            <Icon>edit</Icon>
            <BodyText>Edit</BodyText>
          </Link>
        </PopupItem>
      </FeatureFlag>
      <PopupItem prefix='link' text='Copy Guest View Link' onClick={handleCopyCustomerLink} />
      {!isPresentation && <PopupItem prefix='description' text='Get PDF' loading={showGuestView} onClick={handlePrintPdf} keepOpen={true} />}
      <PopupItem prefix='delete' text='Delete' onClick={handleShowConfirmDelete} keepOpenHidden={true} />
      <PopupItem prefix='send' text='Send' onClick={handleShowSend} keepOpenHidden={true} />
      <PopupItem>
        <Link to={`/sales/new/${salesDoc.number}`}>
          <Icon>file_copy</Icon>
          <BodyText>Duplicate</BodyText>
        </Link>
      </PopupItem>
      <PopupItem>
        <Link to={`/sales/view/${salesDoc._id.toString()}`}>
          <Icon>find_in_page</Icon>
          <BodyText>Preview</BodyText>
        </Link>
      </PopupItem>

      {(!salesDoc.jobId && !isPresentation) &&
        <PopupItem prefix='view_week' text='Create Job' onClick={handleConvertToJob}/>
      }
      {salesDoc.jobId &&
        <PopupItem>
          {!onJobBoard &&
            <Link to={`/sales/job/${salesDoc?.jobId}`}>
              <Icon>view_week</Icon>
              <BodyText>View Linked Job</BodyText>
            </Link>
          }
          {onJobBoard &&
            <Link to={`/jobs/${salesDoc?.jobId}`}>
              <Icon>view_week</Icon>
              <BodyText>View Linked Job</BodyText>
            </Link>
          }
        </PopupItem>
      }
      {(isPresentation) &&
        <PopupItem>
          <Link to={`/sales/convert/${salesDoc.number}`}>
            <Icon>currency_exchange</Icon>
            <BodyText>Convert to SalesDoc</BodyText>
          </Link>
        </PopupItem>
      }

      <PopupItem prefix='assignment_turned_in' text='Add Task' onClick={handleAddTask} />
      {archived &&
        <PopupItem prefix='unarchived' text='UnArchive' onClick={() => handleChangeArchived(false)} />
      }
      {!archived &&
        <PopupItem prefix='unarchived' text='Archive' onClick={() => handleChangeArchived(true)} />
      }
      {accountingConnected && !salesDoc.invoice?._id && permissionFullyPromoted && !isPresentation &&
        <PopupItem prefix='payment' text='Create/Edit Invoice' onClick={handleCreateEditInvoice}/>
      }
      {accountingConnected && salesDoc.invoice?._id && permissionFullyPromoted &&
        <ToolTip
          tip={invoiceLocked &&
            <>
              As there are payments applied, the invoice cannot be updated. Learn more in <Link
              href={'https://help.xero.com/au/Payments_AR_PaidView'} underline>Xero Help</Link>.
            </>}
          placement={Placement.Left}
        >
          <PopupItem prefix='payment' text='Edit Invoice' onClick={handleCreateEditInvoice} disabled={invoiceLocked}/>
        </ToolTip>
      }
      {accountingConnected && invoicePdfLink && permissionFullyPromoted &&
        <PopupItem>
          <Link href={invoicePdfLink}>
            <Icon>description</Icon>
            <BodyText>Get Invoice PDF</BodyText>
          </Link>
        </PopupItem>
      }
      {confirmDelete &&
        <DeleteViewConfirmation onClose={handleDelete} number={salesDoc.number} />
      }
      {showSend &&
        <SendSalesDocModal salesDoc={salesDoc} onClose={handleCloseSend} />
      }
      {showGuestView &&
        <Offscreen>
          <SalesDocGuestPage salesDocId={salesDoc._id} hideActionButtons={true} printPdf={true} onPrintPdfComplete={handlePrintPdfComplete}/>
        </Offscreen>
      }
    </PopupMenuList>
  );
}

export const SalesDocActionMenuButton = ({salesDoc, updateField, refetchSalesDocs}) => (
  <Button menu navStandard text={'Actions'} placement={'bottom-end'}>
    <SalesDocActionMenu salesDoc={salesDoc} updateField={updateField} refetchSalesDocs={refetchSalesDocs} />
  </Button>
);

const DeleteViewConfirmation = ({onClose, number}) => (
  <AttentionConfirmDialog
    onClose={onClose}
    okText={'Delete'}
    cancelText={'Cancel'}
    warningIcon
    mild
  >
    <BodyText>Are you sure you want to delete <b>Sales Doc Number {number}</b></BodyText>
  </AttentionConfirmDialog>
);
