import React from 'react';
import {Dialog, makeStyles, Typography} from '@material-ui/core';
import DialogTitle from '../shared/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import {Close} from '@material-ui/icons';
import {createPortal} from 'react-dom';

const DefaultActionComponent = ({
  SaveButton,
  FooterAddOn,
  onCancel
}) => (
    <>
      <Grid container
        justifyContent={'space-between'}
      >
        <Grid item>
          <Button variant={'text'}
            color={'secondary'}
            onClick={onCancel}
          >
            <Close /> Cancel
          </Button>
        </Grid>
        {FooterAddOn && <Grid item><FooterAddOn /></Grid>}
        <Grid item>
          {SaveButton && <SaveButton />}
        </Grid>
      </Grid>
    </>
  );
const modalDiv = document.getElementById('modals');

export const ModalPortal = ({portal = false, children}) => {
  if (portal){
    return createPortal(children, modalDiv);
  }else{
    return children;
  }
};

const HoopsFormDialog = ({
  DialogTitleProps,
  TitleComponent,
  DialogActionComponent,
  children,
  title,
  onCancel,
  SaveButton,
  FooterAddOn,
  portal = false,
  ...custom
}) => {

  const useStyles = makeStyles((theme) => ({dialogActionContainer: {padding: theme.spacing(2)}}));
  const classes = useStyles();

  return (
    <ModalPortal portal={portal}>
      <Dialog
        maxWidth={'md'}
        fullWidth
        {...custom}
      >
        <DialogTitle onClick={onCancel} {...DialogTitleProps}>
          {TitleComponent ? TitleComponent : <Typography variant={'h4'}>{title}</Typography>}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {!!(SaveButton || FooterAddOn || onCancel) && <DialogActions className={classes.dialogActionContainer}>
          {DialogActionComponent ??
            <DefaultActionComponent onCancel={onCancel}
              FooterAddOn={FooterAddOn}
              SaveButton={SaveButton}
            />
          }
        </DialogActions>}
      </Dialog>
    </ModalPortal>
  );
};

export default HoopsFormDialog;
