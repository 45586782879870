import {Row} from '../Layout';
import React, {useCallback} from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';

export const HOVER_TOOLS_CONTAINER = 'hover-tools-container';

registerGlobalStyle('.hover-tools', (theme) => ({
  position: 'absolute',
  background: theme.colors.background.white,
  borderRadius: theme.shape.borderRadius,
  color: theme.colors.background.contrast.main,
  padding: theme.spacing(.5),
  columnGap: theme.spacing(.5),
  opacity: 0,
  transition: theme.transitions.out.opacity,
  '&>*': {pointerEvents: 'none'},
  'svg': {fontSize: '1.5rem',},
  '&.small svg': {fontSize: '1rem'},
  '&:is(.hover-tools-container:hover .hover-tools), &:hover': {
    opacity: 1,
    transition: theme.transitions.in.opacity,
    boxShadow: theme.shadows.hoverElevation,
    zIndex: theme.zIndex.hovers,
    '&>*': {pointerEvents: 'all'},
  },
}));

export function HoverTools({className, small, children}) {

  const absorbClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Row className={[className, 'hover-tools', small && 'small']} onClick={absorbClick}>
      {children}
    </Row>
  );
}

HoverTools.propTypes = {
  className: HoopsPropTypes.className,
  small: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};
