import {gql} from 'apollo-boost';
import {SINGLE_JOB_FRAGMENT} from './jobs';
import {SINGLE_PRODUCT_FRAGMENT} from './product';
import {ADDRESS_TO_FRAGMENT} from './addressToFragment';

export const SINGLE_PRICE_ELEMENT_FRAGMENT = gql`
  fragment SinglePriceElement on PriceElement {
    _id
    margin
    quantity
    buyPrice
    sellPrice
    variationsBuyPrice
    decorationsBuyPrice
    additionalCostsBuyPrice
    grossMarginTarget
    decorationCosts {
      _id
      markup
      setupPrice
      buyPrice
      sellPrice
      additionalCost
      quantity
      priceBreakQuantityOverride
      description
      displayToCustomer
      position
      colour
      size
      vendorId
      vendor {
        _id
        name
        email
      }
      decorationId
      decoration {
        _id
        name
        setupPrice
        category
        priceBreaks {
          quantity
          price
          _id
        }
        vendor {
          name
          email
          _id
        }
      }
    }
    additionalCosts {
      _id
      name
      markup
      vendorId
      ignoredByJob
      vendor {
        _id
        name
        email
      }
      buyPrice
      sellPrice
    }
    variations {
      _id
      productName
      productCode
      productId
      markup
      size
      colour
      additionalCost
      buyPrice
      buyPriceOverride
      sellPrice
      quantity
      productVariant {
        _id
        sku
        color {
          _id
          name
        }
        size {
          _id
          name
          description
        }
      }
      vendorId
      vendor {
        _id
        name
        email
      }
      displayToCustomer
    }
  }
`;

const SINGLE_QUOTE_FRAGMENT = gql`
  ${SINGLE_PRODUCT_FRAGMENT}
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
  fragment SingleQuote on Quote {
    _id
    terms
    viewed
    status
    number
    documentType
    isSibling
    siblings {
      number
      title
      _id
    }
    siblingIds
    onlinePaymentStatus
    isQuoteAccepted
    customReference
    quoteAcceptedAt
    quoteOwnerId
    quoteOwner {
      _id
      username
      firstName
      lastName
      fullName
    }
    settings {
      canContactAccept
      canContactComment
      canContactPay
      hideTotal
    }
    deadlineAt
    title
    description
    taxes {
      tax {
        name
        rate
      }
      total
    }
    job {
      _id
      completed
    }
    subTotal
    taxTotal
    total
    archived
    createdAt
    createdBy {
      username
      firstName
      lastName
      fullName
    }
    customerId
    customer {
      _id
      name
      email
      phone
      website
      profile
      companyTradingEntityId
      deletedAt
      settings {
        minimumMarkup
        salesDiscount
      }
      addresses {
        _id
        address1
        address2
        country
        city
        postcode
        state
        label
        formattedAddress
      }
    }
    contactId
    contact {
      _id
      firstName
      lastName
      fullName
      phone
      email
    }
    items {
      _id
      imageUrl
      description
      rate
      quantity
      priceLogic
      priceLogicValue
      configuredProductId
      configuredProduct {
        _id
        code
        name
      }
      product {
        ...SingleProduct
      }
      priceElement {
        ...SinglePriceElement
      }
      tax {
        _id
        name
        type
        components {
          name
          rate
        }
      }
    }
    company {
      _id
      currencySymbol
      stripeConnectedAccountId
      companyTradingEntities {
        _id
        name
        phone
        email
        website
        logo
        addresses {
          _id
          address1
          address2
          state
          postcode
          city
          label
          country
          formattedAddress
        }
      }
      addresses {
        _id
        address1
        address2
        state
        label
        city
        country
        postcode
        formattedAddress
      }
    }
    shipTo {
      ${ADDRESS_TO_FRAGMENT}
    }
    billTo {
      ${ADDRESS_TO_FRAGMENT}
    }
    leadSource
    invoice {
      _id
      invoiceNumber
      balance
      totalAmount
      dueDate

      xeroCustomerId
      xeroInvoiceId
      qboInvoiceReference
      qboLastRequestStatus
      errorMessage
    }
  }
`;

const QUOTE_LIST_FRAGMENT = gql`
  fragment QuoteList on Quote {
    _id
    status
    number
    isSibling
    siblings {
        number
        title
        _id
    }
    onlinePaymentStatus
    siblingIds
    total
    subTotal
    customReference
    createdAt
    createdBy {
      firstName
      lastName
      fullName
    }
    company {
      companyTradingEntities {
        _id
        name
        phone
        email
        website
        logo
        addresses {
          _id
          address1
          address2
          state
          postcode
          city
          label
          country
          formattedAddress
        }
      }
    }
    contact {
      firstName
      lastName
      fullName
    }
    customer {
      name
      companyTradingEntityId
    }
    archived
    job {
      _id
      number
    }
    invoice {
      _id
      invoiceNumber
      balance
      totalAmount
      dueDate

      xeroCustomerId
      xeroInvoiceId
      qboInvoiceReference
      qboLastRequestStatus
      errorMessage
    }
  }
`;

const QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on GCQuote {
    _id
    status
    number
    siblings {
      number
      title
      _id
    }
    onlinePaymentStatus
    siblingIds
    isQuoteAccepted
    documentType
    quoteAcceptedAt
    stripeInvoiceId
    quoteOwnerId
    # quoteOwner {
    #   fullName
    # }
    deadlineAt
    createdAt
    viewed
    createdBy {
      username
      firstName
      lastName
      fullName
    }
    company {
      companyTradingEntities {
        _id
        name
        phone
        email
        website
        logo
        addresses {
          _id
          address1
          address2
          state
          postcode
          city
          label
          country
          formattedAddress
        }
      }
    }
    customer {
      name
      companyTradingEntityId
    }
    customerId
    contact {
      firstName
      lastName
      fullName
    }
    customReference
    isSibling
    total
    archived
    subTotal
    taxTotal
    job {
      _id
      number
    }
    invoice {
      _id
      invoiceNumber
      balance
      totalAmount
      dueDate

      xeroCustomerId
      xeroInvoiceId
      qboInvoiceReference
      qboLastRequestStatus
      errorMessage
    }
    leadSource
  }
`;

export const GQL_GET_QUOTES = gql`
  query GetQuotes($page: Int, $pageLimit: Int, $q: String) {
    quotes(page: $page, pageLimit: $pageLimit, q: $q) {
      recordsFiltered
      recordsTotal
      page
      data {
        ...QuoteList
      }
    }
  }

  ${QUOTE_LIST_FRAGMENT}
`;

export const GQL_GET_QUOTE = gql`
  query GetQuote($_id: String, $number: String) {
    quote(_id: $_id number: $number) {
      ...SingleQuote
      siblings {
        ...SingleQuote
      }
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_QUOTE_SET_ARCHIVED = gql`
  mutation QuoteSetArchived($_id: String!, $archived: Boolean!) {
    quoteSetArchived(_id: $_id, archived: $archived) {
      ...SingleQuote
      siblings {
        ...SingleQuote
      }
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_CREATE_QUOTE = gql`
  mutation CreateQuote($quote: QuoteInput!) {
    createQuote(quote: $quote) {
      ...SingleQuote
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_UPDATE_QUOTE = gql`
  mutation UpdateQuote($_id: String!, $quote: QuoteInput) {
    updateQuote(_id: $_id, quote: $quote) {
      ...SingleQuote
      siblings {
        ...SingleQuote
      }
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_ACCEPT_QUOTE = gql`
  mutation AcceptQuote($quoteId: String!) {
    acceptQuote(quoteId: $quoteId) {
      ...SingleQuote
      siblings {
        ...SingleQuote
      }
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_UPSERT_GROUPED_QUOTES = gql`
  mutation UpsertGroupedQuotes($quotes: [QuoteInput!]!, $deletedQuotes: [String!], $updateJob: Boolean, $updateInvoice: Boolean) {
    upsertGroupedQuotes(quotes: $quotes, deletedQuotes: $deletedQuotes, updateJob: $updateJob, updateInvoice: $updateInvoice) {
      quotes {
        ...SingleQuote
        siblings {
          ...SingleQuote
        }
      }
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_UPDATE_QUOTE_STATUS = gql`
  mutation UpdateQuoteStatus($_id: String!, $status: String!) {
    updateQuoteStatus(_id: $_id, status: $status) {
      ...SingleQuote
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_DUPLICATE_QUOTE_ITEM_DATA = gql`
  ${SINGLE_PRODUCT_FRAGMENT}
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
  mutation DuplicateQuoteItemData($quoteItemData: QuoteItemInput!) {
    duplicateQuoteItemData(quoteItemData: $quoteItemData) {
      imageUrl
      description
      rate
      quantity
      product {
        ...SingleProduct
      }
      priceElement {
        ...SinglePriceElement
      }
      tax {
        _id
        name
        type
        components {
          name
          rate
        }
      }
    }
  }
`;

export const GQL_DELETE_QUOTE = gql`
  mutation DeleteQuote($_id: String!) {
    deleteQuote(_id: $_id) {
      _id
    }
  }
`;

export const GQL_GET_PRICE_ELEMENT = gql`
  query GetPriceElement($_id: String!) {
    priceElement(_id: $_id) {
      ...SinglePriceElement
    }
  }
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
`;

export const GQL_CREATE_PRICE_ELEMENT = gql`
  mutation CreatePriceElement($priceElement: PriceElementInput!) {
    createPriceElement(priceElement: $priceElement) {
      ...SinglePriceElement
    }
  }
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
`;

export const GQL_UPDATE_PRICE_ELEMENT = gql`
  mutation UpdatePriceElement(
    $_id: String!
    $priceElement: PriceElementInput!
  ) {
    updatePriceElement(_id: $_id, priceElement: $priceElement) {
      ...SinglePriceElement
    }
  }
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
`;

export const GQL_SEND_EMAIL_LINK = gql`
  mutation SendQuoteLink($_id: String!, $emailOptions: EmailOptionsInput, $smsOptions: SMSOptions, $renderSiblings: Boolean) {
    sendQuoteLink(_id: $_id, emailOptions: $emailOptions, smsOptions: $smsOptions, renderSiblings: $renderSiblings) {
      emailStatus
      message
      quote {
        number
        company {
          companyTradingEntities {
        _id
            name
          }
        }
      }
    }
  }
`;

export const GQL_CONVERT_TO_JOB = gql`
  mutation convertToJob($_id: String!) {
    convertQuoteToJob(_id: $_id) {
      ...SingleJob
      quote {
        number
        _id
      }
    }
  }
  ${SINGLE_JOB_FRAGMENT}
`;

export const GQL_DUPLICATE_QUOTE = gql`
  mutation duplicateQuote($quoteId: String!) {
    duplicateQuote(quoteId: $quoteId) {
      ...SingleQuote
    }
  }
  ${SINGLE_QUOTE_FRAGMENT}
`;

export const GQL_QUOTE_PAGINATION = gql`
    query quotePagination(
        $filter: FilterFindManySearchGCQuoteInput,
        $sort: SortFindManyGCQuoteInput,
        $perPage: Int,
        $page: Int
    ){
        quotePagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...QuoteFragment
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${QUOTE_FRAGMENT}
`;

export const GQL_QUOTE_COMMENT = gql`
  mutation commentQuote(
    $quoteId: String!
    $comment: String!
  ){
    commentQuote(
      quoteId: $quoteId,
      comment: $comment
    ){
      _id
    }
  }
`;
