import {gql} from 'apollo-boost';

export const GQL_SHARE_CATALOG_IMPORT = gql`
  mutation shareImport($_id: ID!, $companyIds: [ID!]! ) {
    shareCatalogImport(_id: $_id, companyIds: $companyIds) {
      _id
      readOnly
      viewOnlyCompanies {
        _id
        name
      }
      vendor {
        name
      }
      status
      createProductsStatus
      linkingImagesStatus
      unzipFilesStatus
      createdAt
      createdBy {
        fullName
        company {
          _id
          companyTradingEntities {
            _id
            name
          }
        }
      }
      numberProducts
      csvFile {
        url
      }
      imageZipFile {
        url
      }
    }
  }`;

export const GQL_REMOVE_SHARED_CATALOG = gql`
  mutation removeSharedCatalog($_id: ID!, $companyId: ID! ) {
    removeSharedCatalog(_id: $_id, companyId: $companyId) {
      _id
      readOnly
      viewOnlyCompanies {
        _id
        name
      }
      vendor {
        name
      }
      status
      createProductsStatus
      linkingImagesStatus
      unzipFilesStatus
      createdAt
      createdBy {
        fullName
        company {
          _id
          companyTradingEntities {
            _id
            name
          }
        }
      }
      numberProducts
      csvFile {
        url
      }
      imageZipFile {
        url
      }
    }
  }`;
export const GQL_START_CATALOG_IMPORT = gql`
    mutation startCatalogImport($_id: ID, $catalogImport: CatalogImportInput) {
        startCatalogImport(_id: $_id, catalogImport: $catalogImport) {
            _id
            vendor {
                name
            }
            status
        }
    }`;

export const GQL_CATALOG_IMPORT = gql`
  mutation SetCatalogImport($_id: ID, $data: CatalogImportInput) {
    catalogImport(_id: $_id, data: $data) {
      _id
      description
      vendor {
        name
      }
      status
    }
  }`;

export const GQL_CATALOG_IMPORTS = gql`
  query CatalogImports ($limit: Int, $skip: Int)  {
    catalogImports(limit: $limit, skip: $skip) {
      _id
      readOnly
      viewOnlyCompanies {
        _id
        name
      }
      vendor {
        name
      }
      description
      status
      createProductsStatus
      linkingImagesStatus
      unzipFilesStatus
      createdAt
      createdBy {
        fullName
        company {
          _id
          companyTradingEntities {
            _id
            name
          }
        }
      }
      numberProducts
      csvFile {
        url
      }
      imageZipFile {
        url
      }
    }
  }
`;

export const GQL_GET_CATALOG_IMPORT = gql`
  query getCatalogImport($_id: ID!) {
    catalogImport(_id: $_id) {
      _id
      readOnly
      viewOnlyCompanies {
        _id
        name
      }
      vendor {
        name
      }
      status
      createProductsStatus
      linkingImagesStatus
      unzipFilesStatus
      createdAt
      createdBy {
        fullName
        company {
          _id
          name
          companyTradingEntities {
            _id
            name
          }
        }
      }
      numberProducts
      csvFile {
        url
      }
      imageZipFile {
        url
      }
    }
  }
`;

export const GQL_GET_CATALOG_IMPORTS = gql`
  query catalogImports($_id: [ID!]) {
    catalogImports(_id: $_id) {
      _id
      readOnly
      viewOnlyCompanies {
        _id
        companyTradingEntities {
            _id
            name
          }
      }
      vendor {
        name
      }
      status
      createProductsStatus
      linkingImagesStatus
      unzipFilesStatus
      createdAt
      createdBy {
        fullName
        company {
          _id
          companyTradingEntities {
            _id
            name
          }
        }
      }
      numberProducts
      csvFile {
        url
      }
      imageZipFile {
        url
      }
    }
  }
`;

export const GQL_ACTIVE_CATALOG_IMPORT = gql`
  query ActiveCatalogImport {
    activeCatalogImport {
      _id
      description
      vendor {
        name
      }
      status
      noImages
    }
  }`;

export const GQL_UNDO_CATALOG_IMPORT = gql`
  mutation undoCatalogImport($_id: ID) {
    undoCatalogImport(_id: $_id) {
      _id
    }
  }
`;
export const GQL_CANCEL_CATALOG_IMPORT = gql`
  mutation cancelCatalogImport($_id: ID) {
    cancelCatalogImport(_id: $_id) {
      _id
    }
  }
`;
export const GQL_SEND_CATALOG_UPDATE = gql`
  mutation SendCatalogUpdate($_id: ID, $emailOptions: EmailOptionsInput) {
    sendCatalogUpdate(_id: $_id, emailOptions: $emailOptions) {
       _id
    }
  }
`;
